.coupon-checker-component {
  h3 {
    @include font-header(700);
    font-size: 12px;
    line-height: 1.1em;
    color: $slate-blue;
    margin-bottom: 20px;
    padding-bottom: 4px;
    border-bottom: 1px solid $slate-blue;
    text-transform: uppercase;
  }
  .coupon-checker-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    .form-field {
      width: calc(100% - 156px);
      margin-bottom: 0;
    }
    button {
      width: 140px;
    }
  }
}
