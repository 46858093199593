.appointment-cancel {
  text-align: left;
  .cancellation-notice {
    p {
      letter-spacing: 0;
      &.needs-payment {
        padding: 12px 16px;
        border-radius: 4px;
        background-color: $error-red;
        color: #fff;
      }
    }
  }
  .payment-info {
    padding: 0 15px 40px;
    margin-left: -15px;
    margin-right: -15px;
    h2 {
      @include font-header(700);
      font-size: 20px;
      line-height: 1.1em;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      margin-bottom: 14px;
      text-align: left;
      color: $dark-grey;
    }
  }
  .payment-info-item {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    max-width: 320px;
    margin: auto;
    h3 {
      @include font-body(700);
      margin-bottom: 0;
      font-size: 14px;
      line-height: 1.25em;
      letter-spacing: 0.1em;
      color: $slate-blue;
    }
    p {
      @include font-body(700);
      color: $slate-blue;
      font-size: 14px;
      line-height: 1.5em;
      margin-bottom: 0;
    }
    .btn-wrapper {
      width: 100%;
      margin-top: 16px;
    }
  }
  .coupon-checker-component {
    text-align: left;
    width: 100%;
    max-width: 320px;
    margin: 32px auto 24px;
    .coupon-checker-wrapper {
      flex-flow: row wrap;
      justify-content: center;
      .form-field {
        width: 100%;
        input {
          margin-bottom: 10px;
          text-align: left;
        }
      }
    }
  }
  .coupon-info {
    text-align: center;
    margin-bottom: 16px;
    p {
      font-size: 16px;
      letter-spacing: 0;
      margin-bottom: 0;
      &.total {
        @include font-header(700);
        font-weight: 700;
        font-size: 24px;
        text-transform: uppercase;
      }
    }
  }
  .payment-fields {
    flex-flow: row wrap;
    .payment-field {
      &.card-number {
        width: 100%;
        margin-right: 0;
        margin-bottom: 24px;
      }
      &.card-expiry {
        width: 47.5%;
        margin-right: 5%;
      }
      &.card-cvc {
        width: 47.5%;
        margin-right: 0;
      }
    }
    .payment-billing-address {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      .form-field {
        &.firstname,
        &.lastname,
        &.address1,
        &.address2 {
          width: 47.5%;
        }
        &.firstname,
        &.address1 {
          margin-right: 5%;
        }
        &.city,
        &.state,
        &.zip {
          width: 30%;
        }
        &.city,
        &.state {
          margin-right: 5%;
        }
      }
    }
  }
}
