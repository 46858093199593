.admin-detail {
  h3 {
    @include font-header(400);
    color: $brand-blue;
    font-size: 18px;
    line-height: 1.1em;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin-bottom: 38px;
  }
  p {
    font-size: 16px;
  }
  .detail-table {
    display: table;
    table-layout: fixed;
    + .detail-table {
      margin-top: 20px;
      border-top: 1px solid #c4c4c4;
    }
    .table-row {
      display: table-row;
    }
    .table-cell {
      display: table-cell;
      padding: 1em 0;
      vertical-align: top;
    }
    .section-label {
      margin-top: 28px;
      margin-bottom: 0;
    }
    p {
      margin-bottom: 0;
      letter-spacing: 0;
      line-height: 1.5em;

      &.status {
        &.pending,
        &.pending-cover,
        &.pending-admin,
        &.pending-list,
        &.pending-list-approval,
        &.submitted,
        &.submitted-timed,
        &.cover-acceptance-needed {
          i {
            color: $color-favorite;
          }
        }
        &.completed,
        &.booked {
          i {
            color: $primary-green;
          }
        }
        i {
          margin-right: 4px;
        }
      }
    }
    .header {
      width: 200px;
      p {
        font-weight: 700;
      }
    }
    .value {
      width: calc(100% - 200px);
      padding-left: 12px;
    }
  }
  .booking-appointment-communications {
    margin: 24px 0;
    padding-top: 20px;
    p {
      letter-spacing: 0;
    }
  }
  .form {
    margin-top: 28px;
  }
  .actions {
    margin-top: 36px;
    button {
      max-width: none;
    }
  }
  .back {
    margin-top: 12px;
  }
  .appt-people {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    &.top {
      padding-bottom: 30px;
      border-bottom: 1px solid #c4c4c4;
      margin-bottom: 30px;
    }
    &.bottom {
      padding-top: 30px;
      border-top: 1px solid #c4c4c4;
      margin-top: 30px;
      padding-bottom: 60px;
    }
    .admin-avatar-section {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: flex-start;
      margin-top: 16px;
      &.family {
        width: 60%;
        max-width: 500px;
      }
      &.sitters {
        width: 40%;
        &.pending-cover {
          .avatar,
          .info {
            opacity: 0.5;
          }
        }
        .sitters > p {
          letter-spacing: 0;
          font-weight: 700;
        }
      }
      &.children {
        width: 100%;
      }
      h4 {
        width: 100%;
        margin-bottom: 10px;
      }
    }
    .btn-wrapper {
      width: 100%;
      margin-top: 10px;
    }
    .wrapper {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      .avatar {
        width: 100px;
        height: 100px;
        margin-right: 5px;
        .avatar-wrapper {
          border: 5px solid #fff;
          box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
        }
      }
      .info {
        display: flex;
        flex-flow: row wrap;
        width: calc(100% - 100px);
        padding-left: 15px;
        h1 {
          @include font-header(700);
          line-height: 2em;
          font-size: 24px;
          width: 100%;
          margin-bottom: 0;
        }
        p {
          width: 100%;
          margin-bottom: 0;
          letter-spacing: 0;
        }
      }
    }
    .children-wrapper {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: stretch;
      .wrapper {
        max-width: 400px;
        margin-top: 16px;
        .avatar {
          width: 60px;
          height: 60px;
          margin-right: 10px;
          .avatar-wrapper {
            border: 0;
            i {
              font-size: 16px;
            }
          }
        }
        .info {
          h1 {
            font-size: 16px;
            line-height: 1.25em;
          }
          p {
            color: $slate-blue;
            letter-spacing: 0;
          }
        }
      }
    }
  }
  .appointment-detail-view {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    height: 100%;
    &.assigning-sitter {
      .appointment-detail {
        &.appointment {
          width: 315px;
          background-color: #f5f5f5;
          .content {
            width: 100%;
          }
        }
      }
    }
    .appointment-detail {
      &.assign-sitters {
        width: calc(100% - 315px);

        .content {
          .admin-program-booking-tabs {
            background-color: #fff;
            .tabs-header {
              display: flex;
              flex-flow: row nowrap;
              align-items: stretch;
              justify-content: space-between;
              border: 1px solid #eeeeee;
              .tab-item {
                width: 33.3333%;
                text-align: center;
                background-color: #fff;
                padding: 8px 0;
                cursor: pointer;
                p {
                  @include font-body(700);
                  color: $slate-blue;
                  font-size: 12px;
                  text-transform: uppercase;
                  line-height: 2em;
                  margin-bottom: 0;
                }
                &.active {
                  background-color: $brand-blue;
                  p {
                    color: #fff;
                  }
                }
                + .tab-item {
                  border-left: 1px solid #eeeeee;
                }
              }
            }
            .tabs-body {
              padding: 20px;
              border: 1px solid #eeeeee;
              border-top: 0;
            }
          }
        }
      }
      &.appointment {
        width: 100%;
        height: 100%;
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        .content {
          padding-left: 20px;
          padding-right: 20px;
          width: calc(100% - 315px);
        }
        .sidebar-right {
          width: 315px;
        }
        .appt-people .admin-avatar-section.family {
          width: 100%;
        }
      }
    }
  }
  .admin-view-sit-log {
    h4.section-label {
      margin-top: 36px;
      margin-bottom: 12px;
    }
  }
  .form-field.datepicker .react-datepicker__close-icon {
    right: 0;
  }
}

.admin-family-booking {
  h4 {
    @include font-header(700);
    color: $primary-green;
    font-size: 20px;
    margin-bottom: 16px;
  }
  .appt-people {
    margin-bottom: 16px;
  }
  .admin-booking-form {
  }
  .calendar-view {
    .arrow {
      position: absolute;
      z-index: 5;
      top: -4px;
      i {
        color: $slate-blue;
      }
      &.prev {
        left: 0;
      }
      &.next {
        right: 0;
      }
      &.disabled {
        i {
          color: #d8d8d8;
        }
        cursor: not-allowed;
        transition: all 0.25s ease;
      }
    }
    .calendars {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      justify-content: space-between;
      .calendar {
        width: 31%;
        margin: 0;
      }
    }
  }
  .appointment-rows {
  }
  .appointment-row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: stretch;
    padding: 20px 0;
    margin-left: -11px;
    margin-right: -11px;
    border-bottom: 1px solid #d2d2d2;
    .form-field-wrapper {
      padding: 0 11px;
      &.date {
        width: 10%;
      }
      &.start_time,
      &.end_time {
        width: 12.5%;
      }
      &.flex {
        width: 15%;
        display: flex;
        flex-flow: row nowrap;
        .form-field {
          width: 50%;
        }
      }
      &.driving {
        width: 15%;
        display: flex;
        flex-flow: row nowrap;
        .form-field {
          width: 50%;
        }
      }
      &.children {
        width: 15%;
      }
      &.provider {
        width: 15%;
      }
      &.cancel {
        width: 5%;
        text-align: center;
      }
    }
    .form-field {
      margin-bottom: 0;
      &.all_children {
        margin-bottom: 4px;
      }
      &.selected_children {
        label.title {
          display: none;
        }
        .avatar {
          display: none;
        }
        .label {
          width: 100%;
          display: flex;
          padding-left: 0;
          .checkbox {
            order: 1;
          }
          p {
            order: 2;
            font-weight: 400;
          }
        }
        .checkbox-group {
          width: 100%;
        }
      }
    }
    .radio-group {
      display: inline-block;
      margin-right: 8px;
    }
  }
  .btn-wrapper {
    margin-top: 16px;
  }
  .cancel-btn {
    appearance: none;
    display: inline-block;
    width: 25px;
    height: 25px;
    background-color: $error-red;
    margin-top: 18px;
    border: 0;
    border-radius: 50%;
    span {
      @include font-header(400);
      color: #fff;
      font-size: 11px;
      line-height: 1.1em;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
