.sitter-profile-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background-color: $light-taupe;
  padding: 16px 5%;
  background-color: rgba(#000, 0.5);
  .close-profile {
    position: fixed;
    top: 16px;
    right: calc(5% + 16px);
    padding: 12px 9px;
    z-index: 5;
    i {
      font-size: 34px;
      color: #fff;
      text-shadow: 0px 0px 24px rgba(0, 0, 0, 0.25);
    }
  }
  .profile-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 2;
  }
  .bsp-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10001;
    background: #dfdfdf;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.05);
    text-align: center;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    .theme-btn {
      margin: 9px 5px;
    }
  }
}

.sitter-profile {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fafafa;
  border-radius: 4px;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.05);
  text-align: left;
  padding-bottom: 60px;
  .sitter-header {
    padding-top: 102px;
    padding-bottom: 102px;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
    }
    .avatar {
      width: 200px;
      height: 200px;
      z-index: 5;
      margin: auto;
      .avatar-wrapper {
        border: 5px solid #fff;
      }
    }
    h3 {
      @include font-header(700);
      z-index: 5;
      font-size: 24px;
      line-height: 1.5em;
      margin: 10px 0;
      color: #fff;
      text-align: center;
      padding: 0 5%;
    }
    p {
      z-index: 5;
      font-weight: 700;
      font-size: 20px;
      text-align: center;
      line-height: 1.5em;
      color: #fff;
      margin-bottom: 0;
      padding: 0 5%;
    }
  }
  .sitter-header__back-link {
    position: absolute;
    right: 10px;
    top: 80px;
    z-index: 10;
    width: 40px;
    height: 40px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sitter-info {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    border: 1px solid #eeeeee;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
    background: linear-gradient(113.21deg, #fbfeff 7.9%, #ffffff 89.94%);
    .sitter-info-item {
      width: 33.333%;
      padding: 19px 0 21px;
      text-align: center;
      h3 {
        @include font-header(700);
        font-size: 24px;
        line-height: 1.1em;
        text-transform: uppercase;
        margin-bottom: 4px;
        letter-spacing: 0.1em;
      }
      &.age {
        h3 {
          color: $primary-green;
        }
      }
      &.exp {
        h3 {
          color: $primary-coral;
        }
      }
      &.appts {
        h3 {
          color: #b26af5;
        }
      }
      p {
        font-weight: 700;
        color: $slate-blue;
        font-size: 9px;
        line-height: 1.1em;
        margin-bottom: 0;
        text-transform: uppercase;
      }
      + .sitter-info-item {
        border-left: 1px solid #eeeeee;
      }
    }
  }
  .sitter-bio {
    padding-bottom: 50px;
    width: 100%;
    max-width: 600px;
    margin: auto;
    .bio-section {
      padding: 31px 15px 9px;
      p {
        font-size: 14px;
        line-height: 1.1em;
        margin-bottom: 1.1em;
      }
      &.bio {
        p {
          line-height: 2em;
        }
      }
    }
  }
}

.sitter-activity-tile {
  width: 128px;
  display: inline-block;
  padding: 10px 7px;
  .tile {
    width: 100%;
    padding-top: 100%;
    // overflow: hidden;
    border-radius: 4px;
    background: linear-gradient(121.66deg, #1b5687 11.14%, #6c7d8e 88.6%);
    p {
      position: absolute;
      color: #fff;
      font-weight: 500;
      margin-bottom: 0 !important;
      font-size: 13px !important;
      left: 0;
      width: 100%;
      text-align: center;
      bottom: 4px;
      padding-left: 7%;
      padding-right: 7%;
      line-height: 1.1em !important;
      height: 3.6em;
      letter-spacing: 0;
    }
    &:before {
      position: absolute;
      top: 14px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 36px;
      line-height: 1.1em !important;
      color: #fff;
      font-family: 'Font Awesome 5 Pro';
    }
    &.meal-preparation {
      background: linear-gradient(121.66deg, #26ccbb 11.14%, #1f81a0 88.6%);
      &:before {
        content: '\f86b';
      }
    }
    &.technology-help {
      background: linear-gradient(121.66deg, #43acf2 11.14%, #1f63a0 88.6%);
      &:before {
        content: '\f87a';
      }
    }
    &.hobbies-and-games {
      background: linear-gradient(121.66deg, #f48693 11.14%, #fc5c65 88.6%);
      &:before {
        content: '\f441';
      }
    }
    &.gardening-assistance {
      background: linear-gradient(121.66deg, #ca92ff 11.14%, #b26af5 88.6%);
      &:before {
        content: '\f801';
      }
    }
    &.various-errand-assistance {
      background: linear-gradient(121.66deg, #1b5687 11.14%, #6c7d8e 88.6%);
      &:before {
        content: '\f290';
      }
    }
    &.pet-care-assistance {
      background: linear-gradient(121.66deg, #fc5c65 11.14%, #be1d43 88.6%);
      &:before {
        content: '\f1b0';
      }
    }
    &.conversation-and-company {
      background: linear-gradient(121.66deg, #f29743 11.14%, #a07c1f 88.6%);
      &:before {
        content: '\f086';
      }
    }
    &.recreational-activities {
      background: linear-gradient(121.66deg, #f486e9 11.14%, #a735c4 88.6%);
      &:before {
        content: '\f45d';
      }
    }
    &.weekly-visits {
      background: linear-gradient(121.66deg, #66c876 11.14%, #6aa91a 88.6%);
      &:before {
        content: '\f783';
      }
    }
    &.daily-visits {
      background: linear-gradient(121.66deg, #666666 11.14%, #484848 88.6%);
      &:before {
        content: '\f784';
      }
    }
    &.other-visits-frequency {
      background: linear-gradient(121.66deg, #2926cc 11.14%, #1f44a0 88.6%);
      &:before {
        content: '\f073';
      }
    }
    &.scrapbooking {
      background: linear-gradient(121.66deg, #43f2f2 11.14%, #1f98a0 88.6%);
      &:before {
        content: '\f302';
      }
    }
    &.light-housekeeping-and-chores {
      background: linear-gradient(121.66deg, #6de36a 11.14%, #47c433 88.6%);
      &:before {
        content: '\f898';
      }
    }
    &.transportation {
      background: linear-gradient(121.66deg, #7e10b1 11.14%, #651989 88.6%);
      &:before {
        content: '\f5e4';
      }
    }
  }
}

@media screen and (min-width: $breakpoint-md) {
  .sitter-profile-modal {
    padding: 32px 10%;
    .close-profile {
      right: calc(10% + 20px);
      top: 32px;
    }
  }
}

@media screen and (min-width: $breakpoint-lg) {
  .sitter-profile {
    .sitter-header {
      h3,
      p {
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

@media screen and (min-width: $breakpoint-xl) {
  .sitter-profile {
    .sitter-bio {
      max-width: 800px;
    }
  }
}

@media screen and (min-width: 1600px) {
  .sitter-profile {
    .sitter-bio {
      max-width: 800px;
    }
  }
}

@media screen and (max-width: $breakpoint-md) {
  .sitter-profile-modal {
    padding-left: 15px;
    padding-right: 15px;
    .close-profile {
      right: 31px;
    }
  }
}
