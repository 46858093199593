// Handy link to Google Font kit just in case
// https://fonts.google.com/specimen/Rubik?selection.family=Open+Sans:ital,wght@0,400;0,700;1,400|Rubik:wght@300;400;700&sidebar.open&query=open+sans

@import url('../../node_modules/@fortawesome/fontawesome-pro/css/all.css');

$open-sans: 'Open Sans', sans-serif;
$rubik: 'Rubik', sans-serif;

$font-body: $open-sans;
$font-header: $rubik;

@mixin font-body($weight: 400) {
  font-family: $font-body;
  font-weight: $weight;
}

@mixin font-header($weight: 400) {
  font-family: $rubik;
  font-weight: $weight;
}
