.admin-cover-request-modal {
  .cover-request-section {
    margin-bottom: 30px;
  }
  .wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    .avatar {
      width: 100px;
      height: 100px;
      margin-right: 5px;
      .avatar-wrapper {
        border: 5px solid #fff;
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
      }
    }
    .info {
      display: flex;
      flex-flow: row wrap;
      padding-left: 15px;
      h1 {
        @include font-header(700);
        line-height: 2em;
        font-size: 24px;
        width: 100%;
        margin-bottom: 0;
      }
      p {
        width: 100%;
        margin-bottom: 0;
      }
    }
    .reason {
      width: 100%;
      p {
        font-size: 16px;
        line-height: 1.5em;
        letter-spacing: 0;
      }
    }
  }
}
