.booking {
  width: 100%;
  height: 100%;
  .booking-component {
    width: 100%;
    height: 100%;
    padding-bottom: 90px;
    .booking-component-inner {
      width: 100%;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
    }
    .regular-booking-wrapper {
      padding-bottom: 30px;
    }
  }
  &.select-flow {
    .booking-header {
      background: linear-gradient(155.74deg, #0f4f85 7.9%, #1f81a0 89.94%);
      .inner {
        align-items: center;
        border-left: 0;
        border-right: 0;
      }
    }
    .booking-component {
      padding-bottom: 0;
    }
  }
  .booking-header {
    height: 80px;
    width: 100%;
    background: linear-gradient(155.74deg, #fbfeff 7.9%, #ffffff 89.94%);
    border: 1px solid #eeeeee;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
    .inner {
      width: 100%;
      height: 100%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: stretch;
      box-sizing: border-box;
      h3 {
        margin-bottom: 0;
        text-transform: uppercase;
        color: #fff;
      }
      .booking-step {
        width: 25%;
        height: 100%;
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;
        text-align: center;
        + .booking-step {
          border-left: 1px solid #eeeeee;
        }
        i {
          color: #d8d8d8;
          font-size: 28px;
          margin-bottom: 6px;
        }
        p {
          color: $slate-blue;
          font-size: 9px;
          margin-bottom: 0;
          line-height: 1.1em;
          letter-spacing: 0.1em;
          text-transform: uppercase;
        }
        &.active {
          i {
            color: $brand-blue;
          }
          p {
            color: $brand-blue;
          }
        }
        &.completed {
          i {
            color: $primary-green;
          }
          p {
            color: $primary-green;
          }
        }
      }
    }
  }
  .booking-tabs {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: space-between;
    border-bottom: 1px solid #e5e5e5;
    margin-left: -15px;
    margin-right: -15px;
    .booking-tab {
      width: 50%;
      padding: 7px 0;
      text-align: center;
      cursor: pointer;
      &.active {
        p {
          color: $primary-green;
          i {
            color: $primary-green;
          }
        }
        &:before {
          content: '';
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          height: 5px;
          background-color: $primary-green;
          transform: translateY(-50%);
          z-index: 2;
        }
      }
      p {
        margin-bottom: 0;
        font-weight: 700;
        color: $slate-blue;
        font-size: 14px;
        i {
          color: $slate-blue;
        }
      }
    }
  }
  .no-available-sitters {
    text-align: center;
    p {
      letter-spacing: 0;
    }
  }
  .booking-body {
    padding: 25px 15px;
  }
  .booking-info {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    h3 {
      font-size: 14px;
      font-weight: 700;
      line-height: 1.1em;
      letter-spacing: 0.02em;
      margin-bottom: 0;
      text-transform: uppercase;
      &.weekday {
        color: $slate-blue;
        font-size: 12px;
        margin-top: 2px;
      }
      span {
        display: inline-block;
      }
    }
    .date {
      border-left: 3px solid $primary-green;
      padding: 6px 0 6px 6px;
    }
    .time {
      padding-left: 16px;
      p {
        font-size: 12px;
        line-height: 1.25em;
        letter-spacing: 0;
        margin-bottom: 0;
        &.wiggle {
          color: $slate-blue;
        }
      }
    }
    .remove {
      button {
        margin-right: 0;
      }
    }
    .sitters-wrapper {
      width: 125px;
      padding-left: 12px;
      .sitter {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        &.unavailable {
          .avatar {
            i {
              font-size: 16px;
            }
          }
        }
        &.unassigned {
          .avatar {
            i {
              font-size: 16px;
            }
          }
        }
      }
      .avatar {
        width: 40px;
        height: 40px;
      }
      .name {
        width: calc(100% - 40px);
        padding-left: 7px;
        p {
          @include font-header();
          margin-bottom: 0;
          font-size: 12px;
          line-height: 1.1em;
          letter-spacing: 0.05em;
        }
      }
    }
    .booking-details {
      width: 100%;
      margin-top: 24px;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      .form-field {
        &.start_time,
        &.end_time {
          width: 47.5%;
        }
      }
    }
    .booking-review {
      padding: 6px 0 0 6px;
      border-left: 3px solid $primary-green;
      width: 100%;
      .inner {
        width: 100%;
        background-color: #eeeeee;
        border-radius: 4px;
        padding: 12px 10px;
      }
      .top {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        h4 {
          margin-bottom: 0;
          &.error {
            color: $error-red;
            i {
              color: $error-red;
            }
          }
        }
        p {
          margin-bottom: 0;
          font-size: 11px;
          line-height: 1.5em;
          i {
            font-size: 12px;
            color: $color-may;
            vertical-align: middle;
          }
        }
        + .fold {
        }
      }
      .fold {
        border-top: 1px solid #fff;
        margin-top: 10px;
        padding-top: 15px;
        padding-bottom: 15px;
      }
      .detail-section {
        .theme-btn {
          position: absolute;
          right: 0;
          top: 0;
          z-index: 2;
        }
        p {
          font-size: 14px;
          line-height: 1.25em;
          margin-bottom: 1.25em;
          letter-spacing: 0;
          span {
            color: $slate-blue;
          }
        }
      }
      .projected-cost {
        background-color: $primary-green;
        padding: 8px 15px;
        border-radius: 4px;
        h4 {
          @include font-body(700);
          font-size: 16px;
          line-height: 1.5em;
          color: #fff;
          margin-bottom: 0;
          &.total {
            border-top: 1px solid #fff;
            margin-top: 4px;
            padding-top: 2px;
          }
        }
        p {
          font-weight: 700;
          font-size: 14px;
          line-height: 1.5em;
          margin-bottom: 0;
          color: #fff;
        }
      }
    }
  }

  .booking-info-wrapper {
    margin-left: -15px;
    margin-right: -15px;
    .booking-info {
      padding: 30px 15px;
      background-color: #fff;
      margin-bottom: 0;
      + .booking-info {
        border-top: 1px solid #eee;
      }
    }
  }

  .booking-template {
    hr {
      display: block;
      margin-top: 0;
      margin-bottom: 0;
      border: 0;
      margin-left: -15px;
      margin-right: -15px;
      height: 1px;
      background-color: #d2d2d2;
    }
    .booking-template-details {
      padding-top: 20px;
      padding-bottom: 10px;
    }
  }

  .sitter-selection-wrapper {
    max-width: 600px;
    width: 100%;
    margin: auto;
  }

  .booking-sitters-section {
    padding-bottom: 40px;
    .sitter {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      margin-left: -15px;
      margin-right: -15px;
      padding: 20px 15px;
      + .sitter {
        border-top: 1px solid #fff;
      }
      .avatar {
        width: 75px;
        height: 75px;
      }
      .info {
        width: calc(100% - 75px - 98px - 84px);
        padding-left: 12px;
        padding-right: 16px;
        h3 {
          @include font-header(700);
          font-size: 18px;
          line-height: 1.3em;
          margin-bottom: 0;
        }
        p {
          margin-bottom: 0;
          letter-spacing: 0;
          &.used {
            @include font-header(700);
            color: $slate-blue;
            font-size: 10px;
            text-transform: uppercase;
          }
        }
      }
      .appts-available {
        width: 68px;
        padding: 8px 6px;
        margin-right: 16px;
        background-color: rgba($primary-green, 0.1);
        border-radius: 4px;
        p {
          font-size: 12px;
          font-weight: 700;
          margin-bottom: 0;
          line-height: 1.25em;
          letter-spacing: 0;
          text-align: center;
          color: $brand-blue;
        }
        &.selected {
          background-color: $primary-green;
          p {
            color: #fff;
          }
        }
      }
      .actions {
        width: 98px;
        .theme-btn {
          width: 100%;
          text-align: center;
          + .theme-btn {
            margin-top: 16px;
          }
        }
      }
      &.saved,
      &.favorite {
        .avatar {
          &:before {
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            width: 28px;
            height: 28px;
            transform: translate(-50%, -50%);
            z-index: 2;
            border-radius: 50%;
          }
          &:after {
            transform: translate(-50%, -50%);
            z-index: 3;
            position: absolute;
            top: 100%;
            left: 50%;
            font-size: 11px;
            color: #fff;
            font-family: 'Font Awesome 5 Pro';
            font-weight: 900;
            content: '\f005';
          }
        }
      }
      &.saved {
        .avatar {
          &:before {
            background-color: #d2d2d2;
          }
        }
      }
      &.favorite {
        .avatar {
          &:before {
            background-color: $color-favorite;
          }
        }
      }
    }
  }

  .appointment-list {
    width: 100%;
    display: table;
    border-spacing: 0 16px;
    .booking-info {
      display: table-row;
    }
    .date,
    .time,
    .sitters-wrapper {
      display: table-cell;
      vertical-align: middle;
    }
    .sitters-wrapper {
    }
  }

  .no-sitters-notice {
    margin-top: 16px;
    width: 100%;
    padding: 12px 16px;
    border-radius: 4px;
    background-color: $error-red;
    ul {
      margin: 0;
      color: #fff;
      line-height: 1em;
    }
    p {
      margin-bottom: 0;
      font-weight: 700;
      font-size: 14px;
      line-height: 1.25em;
      color: #fff;
    }
  }

  .flow {
    padding: 40px 0;
    text-align: center;
    + .flow {
      border-top: 1px solid #c4c4c4;
    }
    i {
      font-size: 43px;
      color: $primary-green;
      margin-bottom: 20px;
    }
    h2 {
      @include font-header(700);
      text-transform: uppercase;
      font-size: 22px;
      line-height: 1.1em;
      letter-spacing: 0.1em;
      color: $brand-blue;
      margin-bottom: 10px;
    }
    p {
      font-size: 16px;
      line-height: 1.5em;
      margin-bottom: 10px;
      letter-spacing: 0;
      &.small {
        margin-top: 10px;
        font-size: 10px;
      }
    }
    a {
      display: inline-block;
    }
  }
  .calendar-wrapper {
    max-width: 385px;
    margin: auto;
    padding-bottom: 10px;
  }
  .book-appointment-form {
    h2 {
      @include font-header(700);
      color: $primary-green;
      font-size: 20px;
      line-height: 1.1em;
      letter-spacing: 0.1em;
      margin-bottom: 20px;
      text-align: center;
      text-transform: uppercase;
    }
    p.dates {
      text-align: center;
    }
    .arrow {
      position: absolute;
      z-index: 5;
      top: -4px;
      i {
        color: $slate-blue;
      }
      &.prev {
        left: 0;
      }
      &.next {
        right: 0;
      }
      &.disabled {
        i {
          color: #d8d8d8;
        }
        transition: all 0.25s ease;
      }
    }
    .calendar {
      margin: 0;
      h5 {
        font-size: 16px;
        line-height: 1.1em;
        letter-spacing: 0.1em;
        text-transform: uppercase;
      }
    }
    form {
      &.time-form {
        display: flex;
        flex-flow: row wrap;
        padding-bottom: 100px;
      }
    }
    .form-group {
      display: flex;
      flex-flow: row wrap;
      align-items: flex-start;
      width: 100%;
      margin-bottom: 0;
      &.address {
        .form-field {
          &.address {
            width: 100%;
            margin-bottom: 8px;
          }
        }
        .confirm-address {
          width: 100%;
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 24px;
          .form-field {
            margin-bottom: 0;
          }
          a {
            font-size: 12px;
            line-height: 1.1em;
            text-decoration: underline;
          }
        }
      }
    }
    .notice {
      margin-left: -15px;
      margin-right: -15px;
      margin-bottom: 20px;
      h3 {
        @include font-body(700);
        color: $dark-grey;
        font-size: 16px;
        line-height: 1.1em;
        letter-spacing: 0.1em;
        text-align: left;
        text-transform: none;
        i {
          font-size: 24px;
          vertical-align: text-bottom;
        }
      }
    }
    .form-field {
      &.start_time,
      &.end_time {
        width: 47.5%;
      }
      &.start_time {
        margin-right: 5%;
      }
      &.driving {
        width: 100%;
        .radio-group {
          display: inline-block;
          + .radio-group {
            margin-left: 16px;
          }
        }
      }
      &.driving_distance,
      &.requested_provider,
      &.notes {
        width: 100%;
      }
      &.address1,
      &.address2 {
        width: 47.5%;
      }
      &.address1 {
        margin-right: 5%;
      }
      &.city,
      &.state,
      &.zip {
        width: 30%;
      }
      &.city,
      &.state {
        margin-right: 5%;
      }
      &.radio {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: flex-start;
        label {
          width: 100%;
        }
        .radio-group {
          margin-right: 12px;
          margin-top: 4px;
        }
      }
      &.child-checkbox {
        .checkbox-group {
          width: 140px;
          height: 120px;
          border: 1px solid #d2d2d2;
          background-color: #fff;
          border-radius: 4px;
          margin-right: 21px;
          margin-bottom: 15px;
          padding: 6px 0 0;
          cursor: pointer;
          &.active {
            background-color: rgba(38, 204, 187, 0.1);
          }
          .inner {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
          }
          .avatar {
            margin: auto;
          }
          .label {
            width: 100%;
            padding-left: 0;
            text-align: center;
          }
        }
      }
      &.flex,
      &.overnight {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        width: 100%;
        padding-top: 16px;
        .radio-title {
          width: 120px;
        }
        .radio-group {
          margin-left: 16px;
          margin-top: 0;
        }
        + .form-field.flex_room {
          width: 50%;
          margin-left: 136px;
          select {
            max-width: 175px;
          }
        }
        + .form-field.num-nights {
          width: 50%;
          margin-left: 136px;
        }
      }
    }
    &.dates {
      .booking-info {
        max-width: 385px;
        padding: 21px 0;
        margin: auto;
        + .booking-info {
          border-top: 1px solid #eeeeee;
        }
      }
    }
  }
  .booking-coupon {
    margin: 24px 0;
  }
  .booking-fees {
    background-color: #eeeeee;
    margin-left: -15px;
    margin-right: -15px;
    .line-item {
      padding: 20px 15px 15px;
      border-bottom: 1px solid #d2d2d2;
      span.info {
        color: $error-red;
      }
      > p {
        font-size: 13px;
        line-height: 1em;
        color: $slate-blue;
        margin: 0;
      }
      &.estimated-cost {
        h3 {
          margin-bottom: 0;
        }
        > p {
          margin-bottom: 14px;
        }
      }
    }
    h3 {
      @include font-header(700);
      font-size: 20px;
      line-height: 1.1em;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      margin-bottom: 14px;
    }
    .fee {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      p {
        margin-bottom: 0;
        letter-spacing: 0;
        &.fee-breakdown {
          @include font-body(700);
          color: $slate-blue;
          font-size: 14px;
          line-height: 1.5em;
          margin-bottom: 0;
        }
      }
      h4 {
        @include font-body(700);
        margin-bottom: 0;
        font-size: 24px;
        line-height: 1.25em;
        letter-spacing: 0.1em;
        &.booking-fee {
          color: $primary-green;
        }
        &.estimated-fee {
          color: $brand-blue;
        }
      }
    }
    .fee-breakdown {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      + .fee {
        margin-top: 18px;
      }
      p {
        @include font-body(700);
        color: $slate-blue;
        font-size: 14px;
        line-height: 1.5em;
        margin-bottom: 0;
      }
      h4 {
        @include font-body(700);
        margin-bottom: 0;
        font-size: 14px;
        line-height: 1.25em;
        letter-spacing: 0.1em;
        color: $slate-blue;
      }
    }
  }
  .payment-info {
    background-color: #eeeeee;
    padding: 20px 15px 80px;
    margin-left: -15px;
    margin-right: -15px;
    h2 {
      @include font-header(700);
      font-size: 20px;
      line-height: 1.1em;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      margin-bottom: 14px;
      text-align: left;
      color: $dark-grey;
    }
  }
  .payment-info-item {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    h3 {
      @include font-body(700);
      margin-bottom: 0;
      font-size: 14px;
      line-height: 1.25em;
      letter-spacing: 0.1em;
      color: $slate-blue;
    }
    p {
      @include font-body(700);
      color: $slate-blue;
      font-size: 14px;
      line-height: 1.5em;
      margin-bottom: 0;
    }
    .btn-wrapper {
      width: 100%;
      margin-top: 8px;
    }
  }
  .payment-fields {
    flex-flow: row wrap;
    .payment-billing-address {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      .form-field {
        &.firstname,
        &.lastname,
        &.address1,
        &.address2 {
          width: 47.5%;
        }
        &.firstname,
        &.address1 {
          margin-right: 5%;
        }
        &.city,
        &.state,
        &.zip {
          width: 30%;
        }
        &.city,
        &.state {
          margin-right: 5%;
        }
      }
    }
  }
  .booking-confirmation {
    text-align: center;
    p {
      letter-spacing: 0;
      a {
        text-decoration: underline;
      }
    }
  }
  .booking-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 5;
    padding: 20px 15px;
    background: #dfdfdf;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.05);
    .form-field.submit {
      margin-bottom: 0;
    }
    a,
    button {
      @include font-body(700);
      font-size: 16px;
      line-height: 1.1em;
      cursor: pointer;
      text-transform: uppercase;
      display: block;
      width: 100%;
      max-width: 100%;
      color: #fff !important;
      text-align: center;
      appearance: none;
      padding: 16px 0;
      &.disabled {
        opacity: 0.3;
      }
    }
  }
}

.sitter-unassigned-modal {
  p {
    letter-spacing: 0;
  }
}

@media screen and (min-width: $breakpoint-md) {
  .booking {
    .booking-header {
      .inner {
        width: 75%;
        max-width: 900px;
        margin: auto;
        border-left: 1px solid #eeeeee;
        border-right: 1px solid #eeeeee;
      }
    }
    .booking-body {
      width: 75%;
      max-width: 900px;
      margin: auto;
      padding-left: 0;
      padding-right: 0;
    }
    .book-appointment-form {
      .notice {
        margin-left: auto;
        margin-right: auto;
      }
    }
    .booking-info-wrapper {
      .booking-info {
        border-radius: 4px;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
        + .booking-info {
          border-top: 0;
          margin-top: 24px;
        }
      }
    }
    .booking-fees {
      margin-left: 0;
      margin-right: 0;
    }
    .payment-info {
      margin-left: 0;
      margin-right: 0;
    }
    .booking-footer {
      a,
      button {
        max-width: 500px;
        margin: auto;
      }
    }
    .booking-template {
      hr {
        display: none;
      }
    }
    .booking-info {
      .booking-details {
        .form-group {
          width: 100%;
          &.overnight,
          &.flex {
            width: 47.5%;
          }
        }
      }
    }
    .booking-template-details {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: stretch;
      p {
        width: 100%;
      }
      .form-group {
        width: 100%;
        &.overnight,
        &.flex {
          width: 47.5%;
        }
      }
    }
    .booking-sitters-section {
      .sitter {
        .info {
          width: calc(100% - 75px - 110px - 84px);
        }
        .actions {
          width: 110px;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-md) {
  .booking {
    .payment-info {
      .payment-fields {
        flex-flow: row wrap;
        width: 100%;
        .payment-field {
          margin-bottom: 24px;
          &.card-number {
            width: 100%;
            margin-right: 0;
          }
          &.card-expiry {
            width: 47.5%;
            margin-right: 5%;
          }
          &.card-cvc-num {
            width: 47.5%;
            margin-right: 0;
          }
        }
      }
    }
  }
}
