main.admin {
  width: 100%;
  height: 100%;

  &.with-sidebar {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
  }
  .content {
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    padding: 20px 30px;
  }
}

.sidebar-right {
  width: 315px;
  height: 100%;
  background-color: #f5f5f5;
  padding: 20px 15px;
  overflow-x: hidden;
  overflow-y: auto;
  > h4 {
    @include font-body(700);
    color: $primary-green;
    font-size: 16px;
    text-transform: uppercase;
    line-height: 1.1em;
    letter-spacing: 0.1em;
    text-align: center;
    width: 100%;
    i {
      color: $primary-green;
      font-size: 18px;
      vertical-align: middle;
      margin-right: 8px;
    }
  }
  .time-change-request {
    background-color: rgba(255, 196, 10, 0.2);
    border-radius: 4px;
    padding: 10px;
    h4 {
      @include font-header(700);
      color: $slate-blue;
      font-size: 12px;
      line-height: 1.1em;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      margin-bottom: 7px;
    }
    p {
      font-size: 13px;
      line-height: 1.25em;
      margin-bottom: 0;
    }
  }

  .sidebar-section {
    margin-bottom: 44px;
    h4 {
      @include font-header(700);
      color: $slate-blue;
      font-size: 12px;
      line-height: 1.1em;
      text-transform: uppercase;
      margin-bottom: 5px;
    }
    p {
      font-size: 13px;
      line-height: 1.5em;
      margin-bottom: 0;
      letter-spacing: 0;
    }
    .form-field {
      margin-top: 11px;
      margin-bottom: 14px;
      textarea {
        min-height: 0;
      }
    }
    &.status {
      h4 {
        margin-bottom: 13px;
      }
      p {
        margin-bottom: 20px;
      }
    }
    .sidebar-actions {
      display: flex;
      flex-flow: column wrap;
      justify-content: flex-start;
      button {
        display: inline-block;
        max-width: none;
        + button {
          margin-top: 12px;
        }
      }
    }
  }
  .button-row {
    text-align: center;
    margin-top: 24px;
    button {
      display: inline-block;
      + button {
        margin-left: 10px;
      }
    }
    a {
      display: inline-block;
    }
  }
}

.k-grid .link {
  color: #1b5687;

  &:hover {
    text-decoration: underline;
  }
}
