// Just some menu stuff
$navHeight: 70px;
$menuWidth: 414px;
$menuTransitionProps: all 0.5s ease;

$apptWidth: 95vw;
$apptTransitionProps: all 0.5s ease;

// Primary Colors
$brand-blue: #1b5687;
$slate-blue: #6c7d8e;
$primary-green: #26ccbb;
$primary-coral: #fc5c65;
$primary-purple: #a75eeb;
$primary-orange: #fd9742;
$dark-grey: #2c2c2c;
$light-taupe: #fafafa;
$light-grey: #d8d8d8;
$x-light-grey: #eeeeee;

// Secondary Colors
$color-jan: #fc5c65;
$color-feb: $primary-orange;
$color-mar: #f7b82d;
$color-apr: #34b939;
$color-may: #26ccbb;
$color-jun: #43acf2;
$color-jul: #2864ec;
$color-aug: #a75eeb;
$color-sep: #e94194;
$color-oct: #d72c2d;
$color-nov: #ef5a1f;
$color-dec: #ffc40a;

// Assign colors to certain states/actions (success, error, favorite, etc)
$error-red: #df1924;
$color-error: $error-red;
$color-favorite: $color-feb;
$color-accept: $primary-green;
$color-delete: $color-error;
$color-save: $slate-blue;

$gradient-blue: linear-gradient(91.03deg, #1b5687 5.96%, #1f81a0 93.74%);
$gradient-green: linear-gradient(
    176.7deg,
    #5dd2b6 5.17%,
    #67c8b1 40.55%,
    #76b9a9 96.15%
  ),
  rgba(0, 0, 0, 0.9);
$gradient-salmon: linear-gradient(158.01deg, #f48693 16.01%, #ff8997 88.87%);
$gradient-grey: linear-gradient(176.7deg, #eaeaea 5.17%, #ededed 96.15%);

// Note these are bumped up 1 tier from their bootstrap counterparts since xs is 0 in their system... ???
// Which means xl is arbitrarily set
// $breakpoint-xs: 0;
// $breakpoint-sm: 576px;
// $breakpoint-md: 768px;
// $breakpoint-lg: 992px;
// $breakpoint-xl: 1200px;
$breakpoint-xxs: 375px;
$breakpoint-xs: 575px;
$breakpoint-sm: 767px;
$breakpoint-md: 991px;
$breakpoint-lg: 1199px;
$breakpoint-xl: 1399px;
