// this will contain styles for both sitter and family settings

.settings {
  &.sitter {
  }
  &.family {
  }
  .billing-section {
    h2 {
      margin-top: 24px;
      margin-bottom: 10px;
      color: #000;
    }
    p {
      &.balance {
        font-size: 16px;
        font-weight: 700;
        line-height: 1.25em;
        letter-spacing: 0.1em;
        margin-bottom: 0;
        &.credit {
          color: $primary-green;
        }
        &.owed {
          color: $error-red;
        }
      }
      &.add-card-prompt {
        margin-top: 8px;
      }
    }
    a {
      &.view {
        font-size: 12px;
        color: $slate-blue;
      }
    }
    table {
      margin-top: 10px;
      td,
      th {
        padding-right: 20px;
        &.right {
          text-align: right;
        }
      }
    }
    &.account-status {
      .theme-btn {
        margin-top: 20px;
      }
    }
    .payment-fields {
      flex-flow: row wrap;
      margin-bottom: 24px;
      .payment-billing-address {
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        .form-field {
          width: 100% !important;
          margin-right: 0 !important;
          &.address2,
          &.city,
          &.state,
          &.zip {
            width: 47.5% !important;
            input {
              width: 100% !important;
              max-width: 100% !important;
            }
          }
          &.initials {
            input {
              max-width: 80px !important;
            }
          }
        }
      }
    }
  }
}

.payment-info-row {
  display: flex;
  width: 100%;
  max-width: 320px;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  i {
    font-size: 40px;
    color: $slate-blue;
  }
  p {
    margin-bottom: 0;
  }
}

.family-pay-balance {
  max-width: 232px;
  margin: auto;
  .subscription {
    margin-bottom: 24px;
  }
  .payment-info-row {
    margin: auto;
    margin-bottom: 20px;
  }
  .coupon-checker-component {
    text-align: left;
    .coupon-checker-wrapper {
      flex-flow: row wrap;
      justify-content: center;
      .form-field {
        width: 100%;
        input {
          margin-bottom: 10px;
          text-align: left;
        }
      }
    }
  }
  .payment-info-wrapper {
    margin-bottom: 24px;
    text-align: left;
    .line-item {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
    }
    h3 {
      @include font-header(700);
      font-size: 12px;
      line-height: 1.1em;
      color: $slate-blue;
      margin-bottom: 20px;
      padding-bottom: 4px;
      border-bottom: 1px solid $slate-blue;
      text-transform: uppercase;
    }
    p {
      margin-bottom: 0;
      font-size: 14px;
      line-height: 1.1em;
      letter-spacing: 0;
    }
  }
  .form-field.balance {
    margin-top: 20px;
  }
  input {
    @include font-body(700);
    width: 100%;
    height: 60px;
    font-size: 28px;
    text-align: center;
    line-height: 1.1em;
    letter-spacing: 0.1em;
    color: $dark-grey;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    margin-bottom: 20px;
  }
  .payment-fields {
    .payment-billing-address {
      width: 100%;
    }
  }
}
