.sitter-cover-request-modal {
  p {
    letter-spacing: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.5em;
    margin-bottom: 0;
  }
  .form-field.sitter_reason {
    label.radio-title {
      font-weight: 700;
      margin-bottom: 1em;
    }
    .radio-group {
      margin-bottom: 1em;
    }
  }
}
