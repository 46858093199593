.notifications {
  margin-right: 30px;

  @media screen and (min-width: $breakpoint-sm) {
    margin-right: 40px;
  }
  .notification-icon {
    // we fix the width so that when the icon swaps it doesn't re-position itself
    width: 50px;
    height: 100%;
    text-align: center;
    border: none;
    background: none;
    padding: 0;
  }
  i.fas {
    display: block;
    height: 100%;
    font-size: 26px;
    line-height: 70px;
    color: $slate-blue;
  }
}

$header_height: 80px;
$triangle_height: 20px;
$sidebar_width: 200px;

.notification-icon__counter {
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  color: #fff;
  font-size: 11px;
}

.notification-icon[aria-expanded='true'] {
  z-index: 1000;
  i.fas {
    color: $primary-orange;
  }

  .notification-icon__triangle {
    position: absolute;
    left: 50%;
    top: $header_height - $triangle_height;
    transform: translateX(-50%);
    width: 36px;
    height: $triangle_height;
    filter: drop-shadow(0 -3px 5px rgba(0, 0, 0, 0.1));
    pointer-events: none;
    &::after {
      position: absolute;
      inset: 0;
      content: '';
      background: #fff;
      clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    }
  }
}

.notification-menu {
  position: fixed;
  z-index: 5;
  top: $header_height;
  left: 20px;
  right: 20px;
  background: white;
  max-height: min(450px, calc(100vh - 100px));
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  display: grid;
  grid-template-rows: 40px 1fr;

  &.is-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
  }
}

.notification-menu__header {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification-menu__listing {
  overflow: auto;
  padding: 0 20px 20px;
}

@media screen and (min-width: $breakpoint-sm) {
  .notifications {
    position: relative;
  }
  .notification-menu {
    width: 400px;
    left: auto;
    right: $sidebar_width;
  }

  body.menu-open .notification-menu {
    right: $sidebar_width + $menuWidth;
  }
}

.notification-menu__item {
  h3 {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
  }
  &.blue h3 {
    color: $brand-blue;
  }

  p {
    font-size: 13px;
    margin: 0;
    letter-spacing: 0;
  }

  time {
    font-weight: bold;
    font-size: 11px;
    color: $dark-grey;
  }

  & + & {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #c4c4c4;
  }
}

.notification-menu__item__actions {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.notification-menu__item__ignore,
.notification-menu__ignore-all {
  font-weight: bold;
  font-size: 13px;
  text-decoration: underline;
  background: none;
  padding: 0;
  border: none;
  color: $slate-blue;

  &:hover {
    text-decoration: none;
  }
}

.notification-menu__item__ignore {
  margin-left: auto;
}
.notification-menu__ignore-all {
  padding: 10px;
}
