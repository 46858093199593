.review-main {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.review-container {
  padding: 15px 15px 80px;
  max-width: 930px;
  margin: 0 auto;
}

.review-header {
  text-align: center;
  margin-bottom: 30px;

  hr {
    width: 100%;
    max-width: 250px;
    height: 1px;
    margin: 20px auto;
    border-top: 1px solid #e5e5e5;
  }
  @media screen and (min-width: $breakpoint-md) {
    display: grid;
    grid-template-columns: 1fr 1px 1fr;
    align-items: center;
    justify-items: center;
    margin-top: 30px;

    hr {
      height: 100%;
      width: 1px;
      border-left: 1px solid #e5e5e5;
      border-top: none;
      margin: 0;
    }
  }
}

.review-header__avatar {
  border-radius: 1000px;
  position: relative;

  display: block;
  width: 120px;
  height: 120px;
  margin: 0 auto 20px;
  background: rgba(#000, 0.1);

  @media screen and (min-width: $breakpoint-md) {
    width: 200px;
    height: 200px;
  }
}

.review-header__title {
  font-size: 20px;
  font-family: $rubik;
  text-transform: uppercase;
  font-weight: bold;
  color: $primary-green;
  margin-bottom: 10px;
  letter-spacing: 0.1em;
}

.review-header__date {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: $dark-grey;
  font-size: 16px;
}

.review-header__time {
  color: $dark-grey;
  font-size: 14px;
}

.review-header__time__duration {
  color: $slate-blue;
}

.review-header__sitter-name {
  font-size: 20px;
  color: $dark-grey;
  font-weight: bold;
}

.review-form {
  background: #fff;
  border-radius: 6px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;

  @media screen and (min-width: $breakpoint-md) {
    padding: 50px;
  }
}

.review-form__label {
  color: $brand-blue;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  display: block;
  width: 100%;

  @media screen and(min-width: $breakpoint-md) {
    margin-bottom: 40px;
  }
}

.review-form__submit {
  display: flex;
  justify-content: center;
}

.review-form__answers {
  display: grid;
  gap: 15px;
  list-style: none;
  padding: 0;

  @media screen and (min-width: $breakpoint-md) {
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
  }
}

.radio-button {
  input {
    position: absolute;
    opacity: 0;
  }
  label {
    background: $x-light-grey;
    border-radius: 6px;
    min-height: 60px;
    padding: 10px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: $slate-blue;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  input:checked + label {
    background: $brand-blue;
    color: #fff;
  }

  input:focus + label {
    box-shadow: 0 0 0 3px rgba($brand-blue, 0.3);
  }
}

.review-form textarea {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #d2d2d2;
  margin-bottom: 15px;
}

.review-form__back {
  margin-top: 15px;
  display: flex;
  justify-content: center;

  button {
    border: none;
    background: none;
    font-weight: bold;
    color: $slate-blue;
    text-decoration: underline;
  }

  button:hover {
    text-decoration: none;
  }
}

.review-form__hint {
  color: $dark-grey;
  letter-spacing: 0;
  font-size: 14px;
  margin: 10px 0 15px;
}

.review-form__block-icon,
.review-form__fav-icon {
  font-size: 96px;
  text-align: center;
  margin-bottom: 30px;
}

.review-form__block-icon {
  color: $primary-coral;
}

.review-form__fav-icon {
  color: $primary-orange;
}
