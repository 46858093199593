.account {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  .cancellation-btn {
    margin-bottom: 24px;
    .theme-btn {
      max-width: 300px;
    }
  }
}

.account-settings-form {
  .form-group {
    width: 100%;
    margin-bottom: 60px;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
  }
  .link-wrapper {
    display: block;
    width: 100%;
    text-align: left;
    a {
      display: inline-block;
      margin-right: 24px;
    }
  }
  .form-field {
    width: 47.5%;
    &.address2 {
      width: 25%;
    }
    &.city,
    &.state,
    &.zip {
      width: 20%;
    }
    &.firstname,
    &.phone1,
    &.address1,
    &.city,
    &.state,
    &.employer,
    &.secondary_email,
    &.smoke,
    &.house_rules {
      margin-right: 5%;
    }
    &.email {
      margin-right: 50%;
    }
    .radio-group {
      display: inline-block;
      margin-right: 20px;
    }
  }
}

.login {
}

.password,
.email,
.login {
  h2 {
    font-size: 22px;
    font-weight: 700;
    line-height: 1.1em;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $brand-blue;
    margin-bottom: 12px;
  }
  .inner {
    padding: 40px 15px;
    width: 100%;
    max-width: 650px;
    margin: auto;
  }
  .form-field.current_password {
    margin-bottom: 24px;
  }
  .cancel-btn {
    text-align: center;
  }
  .forgot {
    margin-bottom: 24px;
    display: block;
  }
}
