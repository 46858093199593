html,
body {
  background-color: #fafafa;
  font-family: $font-body;
  color: $dark-grey;
  font-size: 16px;
}

* {
  position: relative;
  box-sizing: border-box;
}

main {
  padding-top: $navHeight;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-header;
  color: $dark-grey;
}

.theme-h1 {
  @include font-header(700);
  font-size: 28px;
  line-height: 1.1em;
  letter-spacing: 0.1em;
}
.theme-h2 {
  @include font-header(700);
  font-size: 24px;
  line-height: 1.1em;
  letter-spacing: 0.1em;
}
.theme-h3 {
  @include font-header(700);
  font-size: 16px;
  line-height: 1.1em;
  letter-spacing: 0.1em;
}

p {
  font-family: $font-body;
  color: $dark-grey;
  letter-spacing: 0.1em;

  &.program-status-pill {
    @include font-body(400);
    color: #fff;
    font-size: 12px;
    line-height: 1.25em;
    letter-spacing: 0;
    display: inline-block;
    padding: 3px 9px;
    border-radius: 14px;
    margin-bottom: 0;
    &.new,
    &.creating {
      background-color: $slate-blue;
    }
    &.assigning,
    &.assigning-pending,
    &.open {
      background-color: $primary-purple;
    }
    &.proposal-sent {
      background-color: $brand-blue;
    }
    &.accepted,
    &.completed {
      background-color: $primary-green;
    }
    &.rejected,
    &.cancelled {
      background-color: $error-red;
    }
  }
}

a {
  color: $brand-blue;
}

.theme-btn {
  display: inline-block;
  appearance: none;
  border: 0;
  border-radius: 4px;
  font-size: 13px;
  line-height: 1.25em;
  padding: 13px 15px 12px;
  max-width: 225px;
  font-family: $font-header;
  line-height: 1.1em;
  text-transform: uppercase;
  margin-right: 5px;
  text-decoration: none !important;

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  i {
    margin-right: 9px;
  }
  &.blue {
    color: #fff;
    background-color: $brand-blue;
  }
  &.black {
    color: #fff;
    background-color: $dark-grey;
  }
  &.slate-blue {
    background-color: $slate-blue;
    color: #fff;
  }
  &.primary-green {
    background-color: $primary-green;
    color: #fff;
  }
  &.primary-coral {
    background-color: $primary-coral;
    color: #fff;
  }
  &.grey {
    background-color: #d8d8d8;
    color: #2c2c2c;
  }
  &.orange {
    background-color: #fd9742;
    color: #fff;
  }
  &.white {
    background-color: #fff;
    color: $brand-blue;
  }
  &.tall {
    padding-top: 21px;
    padding-bottom: 21px;
  }
  &.small {
    font-size: 11px;
    padding: 10px 20px;
  }
  &.red,
  &.error-red {
    background-color: $error-red;
    color: #fff;
  }
  &.disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
  &.color-favorite {
    background-color: $color-favorite;
    color: #fff;
  }
  &.font-body {
    @include font-body(700);
  }
}

.primary-green {
  color: $primary-green;
}

.tabs {
  display: flex;
  width: 100%;
  margin-bottom: 20px;

  .tab {
    width: 100%;
    text-align: center;
    font-weight: bold;
    border-radius: 5px;
    padding: 10px;
    margin-right: 10px;

    background-color: #fff;
    color: #000;
    border: 1px solid #1b5687;

    &:hover {
      cursor: pointer;
    }

    &.active {
      background-color: #1b5687;
      color: #fff;
    }
  }
}

.section-label {
  @include font-header(400);
  font-size: 14px;
  line-height: 1.1em;
  letter-spacing: 0.1em;
  color: $slate-blue;
  text-transform: uppercase;
  i {
    font-size: 16px;
    color: $color-may;
    vertical-align: middle;
  }
}

.section {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  transition: $menuTransitionProps;
  transition-delay: 0.05s;

  body.menu-open & {
    left: -$menuWidth;
    header {
      left: -$menuWidth;
    }
    .menu {
      left: calc(100% - #{$menuWidth});
    }
  }
  body.appointment-open & {
    left: $apptWidth;
    header {
      left: $apptWidth;
    }
  }
}

.profile-color {
  &.color {
    &.primary-coral {
      color: $primary-coral;
    }
    &.primary-green {
      color: $primary-green;
    }
    &.brand-blue {
      color: $brand-blue;
    }
    &.primary-purple {
      color: $primary-purple;
    }
    &.color-nov {
      color: $color-nov;
    }
    &.color-alt-red {
      color: #de333c;
    }
    &.slate-blue {
      color: $slate-blue;
    }
  }
  &.background {
    &.primary-coral {
      background-color: $primary-coral;
    }
    &.primary-green {
      background-color: $primary-green;
    }
    &.brand-blue {
      background-color: $brand-blue;
    }
    &.primary-purple {
      background-color: $primary-purple;
    }
    &.color-nov {
      background-color: $color-nov;
    }
    &.color-alt-red {
      background-color: #de333c;
    }
    &.slate-blue {
      background-color: $slate-blue;
    }
  }
  &.gradient {
    &.primary-coral {
      background: linear-gradient(164.2deg, #f48693 16.01%, #ff8997 88.87%);
    }
    &.primary-green {
      background: linear-gradient(164.2deg, #26ccbb 16.01%, #5dd2b6 88.87%);
    }
    &.brand-blue {
      background: linear-gradient(164.2deg, #1b5687 16.01%, #1f81a0 88.87%);
    }
    &.primary-purple {
      background: linear-gradient(164.2deg, #b26af5 16.01%, #ca92ff 88.87%);
    }
    &.color-nov {
      background: linear-gradient(164.2deg, #ef5a1f 16.01%, #fd9742 88.87%);
    }
    &.color-alt-red {
      background: linear-gradient(164.2deg, #de333c 16.01%, #e85656 88.87%);
    }
    &.slate-blue {
      background-color: $slate-blue;
    }
  }
}

.sidebar {
  width: 200px;
  height: 100%;
  background: linear-gradient(95.9deg, #fbfeff 7.9%, #ffffff 89.94%);
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
  + .content {
    width: calc(100% - 200px);
  }
  .sidebar-title {
    padding: 14px 10px;
    border-bottom: 1px solid #eeeeee;
    i {
      font-size: 15px;
      vertical-align: middle;
      color: $primary-green;
    }
    h3 {
      @include font-header(400);
      color: $slate-blue;
      font-size: 11px;
      line-height: 1.25em;
      letter-spacing: 0.1em;
      margin-bottom: 0;
    }
  }
  .sidebar-link {
    padding: 10px;
    border-bottom: 1px solid #eeeeee;
    a {
      @include font-body(400);
      color: $dark-grey;
      text-decoration: none;
      font-size: 14px;
      line-height: 2em;
      transition: all 0.25s ease;
      &:hover {
        color: $primary-green;
      }
    }
    &.active {
      a {
        color: $primary-green;
      }
    }
  }
  .sitter-resources {
    display: flex;
    flex-flow: row wrap;
    .sitter-resource {
      width: 100%;
      border-bottom: 1px solid #eeeeee;
      a {
        @include font-body(700);
        display: block;
        text-align: center;
        color: $slate-blue;
        text-transform: uppercase;
        font-size: 18px;
        line-height: 1.1em;
        letter-spacing: 0.1em;
        padding: 24px 15%;
      }
    }
  }
}

a.plain-link {
  display: block;
  &:hover,
  &:active {
    text-decoration: none;
  }
}

.avatar {
  .avatar-wrapper {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 36px;
    line-height: 1em;
    color: #fff;
  }
}

.align_center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.align_items_center {
  display: flex;
  align-items: center;
}

.row {
  display: flex;
  flex-direction: row;
}

.fad {
  cursor: pointer;
}

@media screen and (max-width: $breakpoint-md) {
  .theme-h1 {
    font-size: 24px;
  }
  .theme-h2 {
    font-size: 20px;
  }
  .theme-h3 {
    font-size: 16px;
  }
  .section-label {
    font-size: 11px;
  }

  body.menu-open .section {
    left: -100%;
    header {
      left: -100%;
    }
    .menu {
      left: 0;
    }
  }
  body.appointment-open .section {
    left: 100%;
    header {
      left: 100%;
    }
  }

  .theme-btn {
    &.small {
      padding: 6px 10px;
    }
  }
}
