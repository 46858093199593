.admin-pricing {
  padding-top: 50px;
  padding-bottom: 50px;
  padding-right: 10%;
  padding-left: 10%;
  overflow-x: hidden;
  width: 100%;

  .header {
    display: flex;
    flex: 1;
    flex-direction: row;
    height: 40px;
    margin-bottom: 50px;

    .title {
      flex: 0.9;
    }

    .save-btn {
      flex: 0.1;
    }
  }

  .save-btn {
    width: 100%;
  }

  .form-group {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    h3 {
      width: 100%;
      font-weight: 700;
      color: $slate-blue;
      font-size: 12px;
      line-height: 1.1em;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      margin-bottom: 26px;
      padding-bottom: 4px;
      border-bottom: 1px solid $slate-blue;
    }

    .form-field {
      width: 100%;
    }
  }
}
