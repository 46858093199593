.booking.bulk-booking {
  .booking-header {
    .inner {
      .booking-step {
        width: 33.333%;
        &.completed {
          cursor: pointer;
        }
      }
    }
  }

  .bulk-notice {
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #d2d2d2;
    padding: 8px 14px;
    margin-bottom: 20px;
    &.red {
      background: rgba(244, 134, 147, 0.3);
    }
    &.blue {
      background: rgba(38, 204, 187, 0.15);
    }
    p {
      margin-bottom: 0;
      font-size: 14px;
      line-height: 1.5em;
      letter-spacing: 0;
    }
    .tooltip-wrapper .tooltip-item {
      width: 280px;
      p {
        font-size: 11px;
      }
    }
  }

  .needs-header {
    b {
      color: $primary-green;
    }
  }

  .program-details {
    .form-group.address {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      .form-field {
        &.program_address1 {
          width: 100%;
        }
        &.program_address2,
        &.program_city,
        &.program_state,
        &.program_zip {
          width: 47.5%;
        }
      }
    }
  }

  .week-outline {
    p {
      margin-bottom: 0;
      letter-spacing: 0;
      line-height: 1.25em;
      &.small-notice {
        font-style: italic;
        font-size: 12px;
        margin-top: 9px;
      }
    }
    padding-bottom: 36px;
    margin-bottom: 40px;
    border-bottom: 1px solid #c4c4c4;
  }

  .book-appointment-form {
    &.appointments {
      > p {
        @include font-header(700);
        text-align: center;
        margin-bottom: 30px;
        font-size: 11px;
        text-transform: uppercase;
      }
    }
    &.completed {
      p {
        letter-spacing: 0;
      }
    }
  }

  .bulk-booking.appointments {
    .btn-wrapper {
      text-align: center;
      margin-top: 26px;
      button {
        display: inline-block;
      }
    }
  }

  .date-type-selector {
    background: #d2d2d2;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: 20px;
    .selector {
      width: 50%;
      background-color: transparent;
      transition: all 0.25s ease;
      padding: 17px 0;
      cursor: pointer;
      p {
        @include font-header(700);
        margin-bottom: 0;
        color: $slate-blue;
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        font-size: 13px;
      }
      &.active {
        background-color: $brand-blue;
        p {
          color: #fff;
        }
      }
    }
  }

  .repeating-options {
    background-color: $brand-blue;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    button {
      @include font-header(700);
      appearance: none;
      background-color: transparent;
      border: 0;
      color: #fff;
      font-size: 13px;
      line-height: 1.25em;
      text-transform: uppercase;
      padding-left: 0;
      outline: 0 !important;
      display: block;
      height: 30px;
      width: 100%;
      text-align: left;
      &:after {
        content: '\f077';
        font-family: 'Font Awesome 5 Pro';
        font-weight: 900;
        position: absolute;
        right: 0;
        top: 50%;
        font-size: 13px;
        margin-top: -7px;
        color: #fff;
        z-index: 2;
      }
    }
    .inner {
      padding-bottom: 16px;
      p {
        color: #fff;
        letter-spacing: 0;
        font-size: 14px;
        margin-bottom: 18px;
      }
      .form-field {
        > label {
          display: none;
        }
        .checkbox-group {
          label {
            color: #fff !important;
          }
        }
      }
    }
  }

  .bulk-appointments-list {
    .list-appt {
      padding-bottom: 20px;
      border-bottom: 1px solid #c4c4c4;
      + .list-appt {
        padding-top: 20px;
      }
    }
    .form-group {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      .form-field {
        width: 30%;
        margin-bottom: 9px;
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }
    .appointment-options {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
    }
    .options {
      width: calc(100% - 68px);
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      padding-right: 33px;
      .option {
        opacity: 0.2;
        height: 40px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        height: 30px;
        h4 {
          @include font-header(500);
          font-size: 12px;
          color: $slate-blue;
          text-transform: uppercase;
          margin-bottom: 0;
        }
        i {
          font-size: 18px;
          margin-right: 3px;
          color: $brand-blue;
        }
        &.active {
          opacity: 1;
        }
      }
    }
    button {
      width: 68px;
    }
  }

  .bulk-appointment-form {
    h3 {
      @include font-header(700);
      font-size: 12px;
      line-height: 1.1em;
      margin-bottom: 24px;
      padding-bottom: 4px;
      color: $slate-blue;
      border-bottom: 1px solid $slate-blue;
      text-transform: uppercase;
    }
    .form-group {
      &.times {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        .form-field {
          width: 30%;
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
    .appointment-delete-notice {
      margin-left: -15px;
      margin-right: -15px;
      padding: 20px 24px;
      background-color: rgba(252, 92, 101, 0.3);
      p {
        font-size: 14px;
        line-height: 1.25em;
        margin-bottom: 15px;
        text-align: center;
      }
      button.theme-btn.small {
        margin-top: 14px;
      }
    }
  }

  .checkout-section {
    margin-bottom: 32px;
    > h3 {
      @include font-header(700);
      font-size: 12px;
      padding-bottom: 4px;
      margin-bottom: 10px;
      border-bottom: 1px solid #6c7d8e;
      text-transform: uppercase;
      color: $slate-blue;
      line-height: 1.1em;
    }
    .week-outline {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0;
    }
  }

  .payment-info {
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    background-color: transparent;
  }

  .cost-row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    .cost-cell {
      margin-bottom: 13px;
    }
  }

  .breakdown-section {
    padding-bottom: 20px;
    p {
      font-size: 16px;
      line-height: 1.25em;
      margin-bottom: 0;
      letter-spacing: 0;
      i {
        color: $brand-blue;
      }
      &.right {
        text-align: right;
      }
      span {
        &.small {
          font-size: 12px;
        }
      }
    }
    h3 {
      @include font-body(700);
      font-size: 32px;
      line-height: 1.25em;
      margin-bottom: 0;
      color: $primary-green;
    }
    h4 {
      @include font-body(700);
      font-size: 16px;
      line-height: 1.25em;
      margin-bottom: 0;
      color: $primary-green;
      &.light {
        font-weight: 400;
      }
    }

    + .breakdown-section {
      border-top: 1px solid #2c2c2c;
      padding-top: 20px;
    }

    .cost-row {
      .cost-cell {
      }
    }

    &.total {
      .cost-row .cost-cell {
        margin-bottom: 0;
      }
    }
  }

  .appointment-cost-breakdown {
    background: $primary-green;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    button {
      @include font-header(700);
      appearance: none;
      background-color: transparent;
      border: 0;
      color: #fff;
      font-size: 13px;
      line-height: 1.25em;
      text-transform: uppercase;
      padding-left: 0;
      outline: 0 !important;
      display: block;
      height: 30px;
      width: 100%;
      text-align: left;
      &:after {
        content: '\f077';
        font-family: 'Font Awesome 5 Pro';
        font-weight: 900;
        position: absolute;
        right: 0;
        top: 50%;
        font-size: 13px;
        margin-top: -7px;
        color: #fff;
        z-index: 2;
      }
    }
    .inner {
      p {
        margin-bottom: 0;
        color: #fff;
        font-size: 13px;
        letter-spacing: 0;
        line-height: 1.5em;
        &.appt-cost-title {
          font-weight: 700;
        }
        &.right {
          text-align: right;
        }
        &.large {
          font-size: 16px;
        }
      }
    }
  }

  .coupon-checker-component.program {
    margin: 36px 0 24px;
  }

  .form-field.program_terms_accepted_1,
  .form-field.program_terms_accepted_2 {
    > span {
      width: 100%;
      display: block;
      > label {
        width: 100%;
      }
    }
    .checkbox {
      vertical-align: top !important;
    }
    span.text {
      display: inline-block;
      width: calc(100% - 30px);
    }
    span.required {
      color: $error-red;
    }
  }

  .form-field.program_terms_accepted_1 {
    margin-top: 24px !important;
    margin-bottom: 12px;
  }

  .form-field.program_terms_accepted_2 {
    margin-top: 0;
    margin-bottom: 24px !important;
  }

  .checkout-section-cost-breakdown {
    background-color: rgba(38, 204, 187, 0.1);
    padding: 20px;
    margin-left: -15px;
    margin-right: -15px;
  }
}

.bulk-appointment-calendar {
  margin-top: 20px;
  margin-left: -15px;
  margin-right: -15px;
  background-color: #fff;
  padding: 20px 15px;
  i.fa-calendar-star {
    color: $slate-blue;
  }
  i.fa-moon-stars {
    color: $brand-blue;
  }
  i.fa-repeat {
    color: $primary-green;
  }
  i.fa-flag {
    color: $color-favorite;
  }
  .multiple-icon {
    @include font-header(700);
    color: $primary-green;
    line-height: 1em;
    text-transform: uppercase;
  }
  .selected-appt-icon {
    width: 1em;
    height: 1em;
    background: rgba(167, 94, 235, 0.3);
    border: 1px solid #c4c4c4;
    line-height: 1em;
    display: inline-block;
  }
  .bulk-calendar-legend {
    background: rgba(27, 86, 135, 0.05);
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    button {
      @include font-header(700);
      appearance: none;
      background-color: transparent;
      border: 0;
      color: $brand-blue;
      font-size: 13px;
      line-height: 1.25em;
      text-transform: uppercase;
      padding-left: 0;
      outline: 0 !important;
      display: block;
      height: 30px;
      width: 100%;
      text-align: left;
      &:after {
        content: '\f077';
        font-family: 'Font Awesome 5 Pro';
        font-weight: 900;
        position: absolute;
        right: 0;
        top: 50%;
        font-size: 13px;
        margin-top: -7px;
        color: $brand-blue;
        z-index: 2;
      }
    }
    .inner {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: center;
      .legend-icon {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: center;
        padding: 10px 0;
        .icon {
          font-size: 18px;
        }
        p {
          @include font-header(500);
          font-size: 12px;
          margin-bottom: 0;
          text-transform: uppercase;
          display: inline-block;
          margin-left: 4px;
        }
      }
    }
  }
  .bulk-calendar-header {
    p {
      letter-spacing: 0;
      margin: 14px 0 24px;
    }
  }
  .bulk-calendar {
    .bulk-cal.week {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      border: 1px solid #eeeeee;
      margin-bottom: 8px;
      &.title {
        border: 0;
        margin-bottom: 4px;
      }
    }
    .bulk-cal.day {
      width: calc(100% / 7);
      overflow: hidden;
      .day-inner {
        padding-top: 100%;
        min-height: 60px;
      }
      .content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      p {
        @include font-header(400);
        color: $slate-blue;
        font-size: 11px;
        line-height: 1.1em;
        margin-bottom: 0;
        position: absolute;
        top: 4px;
        left: 6px;
        text-transform: uppercase;

        &.title {
          position: relative;
          width: 100%;
          text-align: center;
          font-size: 9px;
          top: auto;
          left: auto;
        }
      }
      &.random {
        background-color: rgba(167, 94, 235, 0.3);
      }
      &.repeating {
        background-color: rgba(38, 204, 187, 0.1);
      }
      .appt-top-icons {
        position: absolute;
        top: 2px;
        right: 2px;
        line-height: 1em;
        i.fa-calendar-star {
          font-size: 14px;
        }
        i.fa-flag {
          font-size: 14px;
        }
        i + i {
          margin-left: 3px;
        }
      }
      .appt-icons {
        position: absolute;
        bottom: 16px;
        height: 24px;
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        i.fa-repeat {
          font-size: 22px;
        }
        i.fa-moon-stars {
          font-size: 16px;
        }
        i.fa-calendar-star {
          font-size: 22px;
        }
        i + i {
          margin-left: 3px;
        }
      }
      .num-appts {
        position: absolute;
        bottom: 2px;
        text-align: center;
        width: 100%;
        .icon {
          font-size: 14px;
        }
      }
      &.month-cap {
        &:before {
          content: '';
          width: 4px;
          height: 100%;
          left: 0;
          top: 0;
          z-index: 2;
          position: absolute;
        }
        p {
          font-weight: 500;
          text-transform: uppercase;
        }
        &.color-jan {
          &:before {
            background-color: $color-jan;
          }
          p {
            color: $color-jan;
          }
        }
        &.color-feb {
          &:before {
            background-color: $color-feb;
          }
          p {
            color: $color-feb;
          }
        }
        &.color-mar {
          &:before {
            background-color: $color-mar;
          }
          p {
            color: $color-mar;
          }
        }
        &.color-apr {
          &:before {
            background-color: $color-apr;
          }
          p {
            color: $color-apr;
          }
        }
        &.color-may {
          &:before {
            background-color: $color-may;
          }
          p {
            color: $color-may;
          }
        }
        &.color-jun {
          &:before {
            background-color: $color-jun;
          }
          p {
            color: $color-jun;
          }
        }
        &.color-jul {
          &:before {
            background-color: $color-jul;
          }
          p {
            color: $color-jul;
          }
        }
        &.color-aug {
          &:before {
            background-color: $color-aug;
          }
          p {
            color: $color-aug;
          }
        }
        &.color-sep {
          &:before {
            background-color: $color-sep;
          }
          p {
            color: $color-sep;
          }
        }
        &.color-oct {
          &:before {
            background-color: $color-oct;
          }
          p {
            color: $color-oct;
          }
        }
        &.color-nov {
          &:before {
            background-color: $color-nov;
          }
          p {
            color: $color-nov;
          }
        }
        &.color-dec {
          &:before {
            background-color: $color-dec;
          }
          p {
            color: $color-dec;
          }
        }
      }
      + .bulk-cal.day {
        border-left: 1px solid #eeeeee;
        &.title {
          border-left: 0;
        }
      }
    }
  }
}
