// Variables
@import 'variables';
@import 'fonts';

// Bootstrap & Vendor
@import '~bootstrap/scss/bootstrap';
@import '~@progress/kendo-theme-default/dist/all.css';
@import '~react-toastify/dist/ReactToastify.css';
@import '~react-datepicker/dist/react-datepicker.css';

// Global
@import 'global';
@import 'header';

// Components
@import 'components/components';

// Device-specific
@import 'devices/device-ios';
