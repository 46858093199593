.registration {
  width: 100%;
  height: 100%;
  border-bottom: 3px solid $primary-coral;

  .onboarding-buttons {
    padding: 10px 14px;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    background: #ffffff;
    border: 1px solid #d2d2d2;
    box-sizing: border-box;
    border-radius: 5px;
    text-align: left;
    margin-bottom: 20px;
    .onboarding-button-checkbox {
      display: block;
      width: 40px;
      height: 40px;
      border-radius: 20px;
      background-color: #f0f0f0;
    }
    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #cbcbcb;
    }
    p {
      letter-spacing: 0;
      width: calc(100% - 40px);
      padding-left: 15px;
    }
    &:hover {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    &.active {
      background-color: $primary-green;
      .onboarding-button-checkbox {
        background-color: #fff;
      }
      i {
        color: $primary-green;
      }
      p {
        color: #fff;
      }
    }
  }

  .signup-section {
    margin-top: 25px;
    margin-bottom: 40px;
    h3 {
      @include font-header(700);
      font-size: 22px;
      text-align: center;
      margin-bottom: 15px;
      text-transform: uppercase;
    }
    p {
      font-size: 14px;
      font-weight: 700;
      line-height: 1.5em;
      letter-spacing: 0.05em;
      margin-bottom: 10px;
      text-align: center;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      text-align: left;
    }
    li {
      display: inline-block;
      font-size: 13px;
      line-height: 1.5em;
      margin-bottom: 3px;
    }
    &.allowed {
      h3 {
        color: $primary-green;
      }
      li {
        width: 50%;
      }
      i {
        color: $primary-green;
      }
    }
    &.prohibited {
      h3 {
        color: $color-error;
      }
      li {
        width: 100%;
      }
      i {
        color: $color-error;
      }
    }
  }

  .form-group {
    > h3 {
      width: 100%;
      font-weight: 700;
      color: $slate-blue;
      font-size: 12px;
      line-height: 1.1em;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      margin-bottom: 26px;
      padding-bottom: 4px;
      border-bottom: 1px solid $slate-blue;
    }
  }

  .submit-btn {
    @include font-body(700);
    width: 100%;
    max-width: 400px;
    font-size: 16px;
    letter-spacing: 0.1em;
    padding: 22px 0 22px;
    display: block;
    margin: auto;
    i {
      margin-right: 8px;
    }
  }

  .care-profile-onboarding {
    // Default state is incomplete - add class & overwrite styles for complete
    border: 1px solid #d2d2d2;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: 100%;
    max-width: 400px;
    margin-right: 30px;
    margin-bottom: 30px;
    .care-profile-status {
      width: 100px;
      height: 100px;
      background: #f0f0f0;
      border-right: 1px solid #d2d2d2;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: center;
      text-align: center;
      i {
        color: $slate-blue;
        font-size: 31px;
      }
      p {
        @include font-header();
        color: $slate-blue;
        text-transform: uppercase;
        font-size: 12px;
        margin-bottom: 0;
      }
    }
    .care-profile-content {
      width: calc(100% - 100px);
      height: 100px;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      padding-left: 20px;
      p {
        letter-spacing: 0;
      }
      .care-profile-btns {
        button.theme-btn {
          margin-right: 10px;
        }
      }
    }
    &.complete {
      .care-profile-status {
        background-color: $primary-green;
        i {
          color: #fff;
        }
        p {
          color: #fff;
        }
      }
    }
  }

  .care-contact-wrapper {
    display: flex;
    flex-flow: row wrap;
  }

  .care-contact-onboarding {
    width: 100%;
    max-width: 300px;
    height: 150px;
    background: #ffffff;
    border: 1px solid #d2d2d2;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 20px 13px 45px;
    margin-right: 18px;
    margin-bottom: 20px;
    p {
      font-size: 13px;
      line-height: 1.25em;
      margin-bottom: 0;
      letter-spacing: 0;
    }
    a {
      font-size: 13px;
      letter-spacing: 0;
      line-height: 1.25em;
      margin-top: 2em;
      margin-bottom: 0;
      text-decoration: underline;
      &.edit {
        color: $slate-blue;
        margin-right: 15px;
      }
      &.remove {
        color: $color-error;
      }
    }
    .actions {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .add-new-btn {
    max-width: 400px;
    margin-bottom: 100px;
  }

  // The styles below this line are... an absolute mess. i really dont know what i was doing
  // I'm going to do my best to clean up the styles and as I do, i'll move them above this section
  // That way we can kinda sorta keep track of what could still use some rewriting
  &.step-1 {
    display: flex;
    flex-flow: row nowrap;
    .form {
      width: 50%;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      padding-top: 40px;
      padding-bottom: 40px;
    }
    .inner {
      width: 100%;
      max-width: 453px;
      max-height: 100%;
    }
    .form-header {
      text-align: center;
      padding-bottom: 40px;
      margin-bottom: 40px;
      border-bottom: 1px solid #c4c4c4;
      h2 {
        @include font-header(700);
        color: $brand-blue;
        font-size: 24px;
        line-height: 1.1em;
        letter-spacing: 0.1em;
        margin-bottom: 13px;
        text-transform: uppercase;
      }
      p {
        font-size: 16px;
        line-height: 1.5em;
        letter-spacing: 0.1em;
        margin-bottom: 0;
      }
    }
    .signup-completed {
      text-align: center;
      h2 {
        @include font-header(700);
        color: $brand-blue;
        font-size: 24px;
        line-height: 1.1em;
        letter-spacing: 0.1em;
        margin-bottom: 13px;
        text-transform: uppercase;
      }
      p {
        font-size: 18px;
        line-height: 1.5em;
        letter-spacing: 0.1em;
        margin-bottom: 0;
      }
    }
    .image {
      width: 50%;
      height: 100%;
      background-image: url('/assets/images/companion-care-header.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 40% 50%;
    }
    &.sitter {
      .form-field {
        &.boolean {
          margin-bottom: 10px;
        }
        &.recaptcha {
          margin-top: 20px;
        }
      }
    }
  }
  &.step-2,
  &.step-3,
  &.step-4,
  &.step-5,
  &.step-6 {
    overflow-x: hidden;
    overflow-y: scroll;
    padding-top: 30px;
    padding-bottom: 40px;
    .form {
      width: 65%;
      margin: auto;
      .form-header {
        h2 {
          font-weight: 700;
          font-size: 21px;
          text-transform: uppercase;
          color: $brand-blue;
          line-height: 1.1em;
          letter-spacing: 0.1em;
          margin-bottom: 20px;
          margin-top: 48px;
        }
      }
      .form-group {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        .firstname,
        .phone1,
        .address1,
        .city,
        .state,
        .zip,
        .email,
        .text-date-picker,
        .vehicle,
        .vehicle_year,
        .vehicle_model,
        .high_school_attended,
        .college_name,
        .first_aid_certified,
        .hear_about_us,
        .coupon,
        .employed,
        .emergency_contact_name,
        .marital_status,
        .help_standing_up,
        .relationship {
          margin-right: 5%;
        }
        .college_field {
          margin-right: 50%;
        }
        .employer,
        .smoke {
          margin-right: 10%;
        }
        &.accept-terms {
          .form-field {
            margin-bottom: 20px;
            width: 100%;
            &.initials {
              width: 80px;
            }
          }
        }
      }
      .form-field {
        width: 47.5%;
        .form-field {
          width: 100%;
        }
        &.address2 {
          input[type='text'] {
            max-width: 192px;
          }
        }
        &.city {
          width: 20%;
        }
        &.state {
          width: 20%;
        }
        &.zip {
          width: 20%;
        }
        &.employer,
        &.sexual_criminal_offense,
        &.smoke {
          width: 40%;
        }
        &.button {
          margin: auto;
        }
        &.textarea {
          width: 100%;
        }
        .radio-group {
          display: inline-block;
          margin-right: 20px;
        }
      }
    }
  }
  &.step-2.sitter {
    .own_children {
      width: 100%;
    }
    .willing_transport_children,
    .recent_background_check,
    .arrested,
    .felony_misdemeanor,
    .dui {
      width: 100%;
    }
  }
  &.step-3,
  &.step-4 {
    .form-field {
      &.button.submit {
        margin-left: 0;
        margin-bottom: 40px;
        text-align: left;
        button {
          display: inline-block;
        }
      }
      &.button.next-step {
        margin: auto;
      }
    }
  }
  &.private-profile {
    .form-field {
      &.select-search {
        margin-right: 50%;
      }
    }
  }
  &.public-profile {
    .form-field {
      &.intro,
      &.accepted_terms {
        width: 100%;
      }
    }
  }
  &.payment {
    .form .form-group .hear_about_us {
      margin-right: 0;
    }
    .coupon-checker-component {
      width: 47.5%;
      margin-right: 5%;
      h3 {
        display: none;
      }
      .form-field {
        width: calc(100% - 156px) !important;
        margin-bottom: 0 !important;
      }
    }
  }
  &.completion-page {
    overflow-x: hidden;
    overflow-y: scroll;
    padding-top: 30px;
    padding-bottom: 40px;
    .form {
      width: 65%;
      margin: auto;
    }
    .form-header {
      text-align: center;
      h2 {
        font-weight: 700;
        font-size: 21px;
        text-transform: uppercase;
        color: $brand-blue;
        line-height: 1.1em;
        letter-spacing: 0.1em;
        margin-bottom: 20px;
        margin-top: 48px;
      }
    }
    .form-body {
      padding: 30px 0;
      border-top: 1px solid #c4c4c4;
      border-bottom: 1px solid #c4c4c4;
      text-align: center;
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: inline-block;
        text-align: left;
        li {
          margin-bottom: 20px;
          &:nth-last-of-type(1) {
            margin-bottom: 0;
          }
        }
      }
      p {
        margin-bottom: 0;
        &.mini-header {
          margin-bottom: 20px;
          font-weight: 700;
          font-size: 18px;
          letter-spacing: 0.1em;
        }
      }
      .form-step-circle {
        width: 35px;
        height: 35px;
        margin: auto;
        border-radius: 50%;
        background-color: $color-may;
        vertical-align: middle;
        display: inline-block;
        margin-right: 10px;
        i {
          position: absolute;
          top: 50%;
          left: 50%;
          font-size: 18px;
          line-height: 1.1em;
          transform: translate(-50%, -50%);
          color: #fff;
        }
      }
    }
    .form-footer {
      text-align: center;
      margin-top: 30px;
      h3 {
        @include font-body(700);
        font-size: 18px;
        line-height: 1.1em;
        letter-spacing: 0.1em;
        margin-bottom: 20px;
      }
      p {
      }
    }
    button {
      @include font-body(700);
      display: block;
      margin: 40px auto 0;
      font-size: 16px;
      letter-spacing: 0.1em;
      max-width: 400px;
      width: 100%;
    }
    &.sitter {
      .form-body {
        .form-step-circle {
          background-color: $brand-blue;
          line-height: 35px;
          font-size: 20px;
          @include font-body(700);
          color: #fff;
          text-align: center;
        }
      }
    }
  }
  &.availability {
    .form {
      max-width: 800px;
      .form-group {
        .availability-selector {
          .availability {
            h3 {
              @include font-body(700);
              width: 100%;
              font-size: 16px;
              line-height: 1.5em;
              margin-bottom: 5px;
              letter-spacing: 0.1em;
              color: $dark-grey;
              text-transform: none;
              border-bottom: 0;
            }
          }
        }
      }
    }
  }
  .billing-address {
    width: 100%;
    max-width: 450px;
    p {
      font-size: 16px;
      line-height: 1.25em;
      display: inline-block;
    }
    button {
      float: right;
    }
  }
  .complete-registration {
    width: 100%;
    background: rgba(38, 204, 187, 0.1);
    border: 1px solid #eeeeee;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 20px 25px;
    h3 {
      width: 100%;
      font-weight: 700;
      color: $slate-blue;
      font-size: 12px;
      line-height: 1.1em;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      margin-bottom: 10px;
      padding-bottom: 4px;
      border-bottom: 1px solid $slate-blue;
    }
    h4 {
      @include font-body(700);
      font-size: 22px;
      line-height: 1.5em;
      margin-bottom: 0;
      span {
        text-decoration: line-through;
      }
    }
    button {
      margin: auto;
    }
  }
}

@media screen and (max-width: $breakpoint-lg) {
  .registration {
    &.step-1 {
      .form {
        width: 60%;
      }
      .image {
        width: 40%;
      }
    }
    &.step-2,
    &.step-3,
    &.step-4,
    &.step-5,
    &.step-6 {
      .form {
        width: 80%;
        margin: auto;
        .form-header {
          h2 {
            margin-top: 38px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-md) {
  .registration {
    &.step-1 {
      flex-flow: row wrap;
      overflow-x: hidden;
      overflow-y: scroll;
      .form {
        width: 100%;
        height: auto;
        order: 2;
        overflow: auto;
        padding-bottom: 80px;
      }
      .image {
        width: 100%;
        height: auto;
        order: 1;
        padding-top: 35%;
        max-height: 350px;
        background-position: center;
        // background-image: url('/assets/images/register-mobile.jpg');
      }
    }
    &.step-2,
    &.step-3,
    &.step-4,
    &.step-5,
    &.step-6,
    &.step-7 {
      padding-bottom: 80px;
      .form {
        width: 100%;
        padding: 0 15px;
        margin: auto;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-sm) {
  .registration {
    &.step-1 {
      .inner {
        width: 100%;
        max-width: 100%;
        max-height: none;
        padding: 0 15px;
      }
      .image {
        min-height: 200px;
      }
    }
    &.step-2,
    &.step-3,
    &.step-4,
    &.step-5,
    &.step-6 {
      .form {
        .form-group {
          &.left-align {
            justify-content: space-between;
          }
        }
        .form-field {
          width: 100% !important;
          margin-right: 0 !important;
          &.address2,
          &.city,
          &.state,
          &.zip {
            width: 47.5% !important;
            input {
              width: 100% !important;
              max-width: 100% !important;
            }
          }
          &.initials {
            input {
              max-width: 80px !important;
            }
          }
        }
      }
    }
    &.payment {
      .coupon-checker-component {
        width: 100%;
        margin-right: 0;
        margin-bottom: 24px;
        .form-field {
          width: calc(100% - 116px) !important;
        }
        button {
          width: 100px;
        }
      }
    }
    .payment-fields {
      flex-flow: row wrap;
      width: 100%;
      .payment-field {
        margin-bottom: 24px;
        &.card-number {
          width: 100%;
          margin-right: 0;
        }
        &.card-expiry {
          width: 47.5%;
          margin-right: 5%;
        }
        &.card-cvc-num {
          width: 47.5%;
          margin-right: 0;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-xs) {
  .registration {
    &.step-1 {
      .image {
        min-height: 150px;
      }
    }
  }
}
