.time-change-request {
  text-align: left;
  .form-group.times {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
    .form-field {
      width: 47.5%;
    }
    p {
      width: 100%;
      text-align: center;
      color: $error-red;
    }
  }
}
