html.device-ios.on-device {
  .booking .booking-component .regular-booking-wrapper {
    padding-bottom: 120px;
  }
  .booking.select-flow .booking-component {
    padding-bottom: 0;
    .booking-body {
      padding-bottom: 120px;
    }
  }
  .appointment-detail-container {
    .appt-info.sitter {
      padding-bottom: 180px;
    }
    .appt-section.child-info {
      margin-bottom: 120px;
    }
  }
  .profile .profile-body {
    padding-bottom: 120px;
  }
  .dashboard .list-view {
    padding-bottom: 120px;
  }
  .registration {
    &.step-2,
    &.step-3,
    &.step-4,
    &.step-5,
    &.step-6,
    &.step-7 {
      padding-bottom: 140px;
    }
  }
  main.program-listing .program-inner {
    padding-bottom: 140px;
  }
  .appointment-detail-container .appt-detail-actions {
    // padding-bottom: 120px;
  }
  main.program-detail {
    .program-inner {
      padding-bottom: 140px;
    }
    .sidebar-right {
      padding-bottom: 140px;
    }
  }
  .review-container {
    padding-bottom: 140px;
  }
}
