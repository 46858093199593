main.availability {
  width: 100%;
  height: 100%;

  &.with-sidebar {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
  }
  .content {
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    padding: 20px 30px;
  }
}

.sitter-availability {
  h4 {
    @include font-header(700);
    color: $primary-green;
    font-size: 20px;
    margin-bottom: 16px;
  }
  .appt-people {
    margin-bottom: 16px;
  }
  .sitter-availability-form {
    .submit-wrapper {
      margin-top: 30px;
    }
  }
  .calendar-view {
    .arrow {
      position: absolute;
      z-index: 5;
      top: -4px;
      i {
        color: $slate-blue;
      }
      &.prev {
        left: 0;
      }
      &.next {
        right: 0;
      }
      &.disabled {
        i {
          color: #d8d8d8;
        }
        cursor: not-allowed;
        transition: all 0.25s ease;
      }
    }
    .calendars {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      justify-content: space-between;
      .calendar {
        width: 31%;
        margin: 0;
        h5 {
          text-transform: uppercase;
        }
      }
    }
  }
  .availability-row {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
    padding: 20px 50px 20px 120px;
    margin-left: -11px;
    margin-right: -11px;
    border-bottom: 1px solid #d2d2d2;
    .timeslot {
      width: 225px;
      height: 60px;
      background: rgba(38, 204, 187, 0.15);
      border: 1px solid #d2d2d2;
      border-radius: 4px;
      align-items: center;
      display: flex;
      padding: 10px;
      margin-right: 10px;
      margin-bottom: 8px;
      .header {
        position: absolute;
        top: 5px;
        font-size: 11px;
      }

      .slot {
        padding-top: 10px;
      }
    }
    .add-time-slot {
      width: 175px;
      background-color: #fff;
      height: 60px;
      padding: 10px;
      border: 1px solid #d2d2d2;
      border-radius: 4px;
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-bottom: 8px;
    }
    .fa-plus-circle {
      color: $primary-green;
      margin-left: 30px;
    }
    .fa-edit {
      color: $primary-green;
      margin-left: 30px;
      cursor: pointer;
    }
    .form-field-wrapper {
      padding: 0 11px;
      &.date {
        @include font-header(700);
        position: absolute;
        left: 0;
        top: 41px;
        width: 120px;
        font-weight: bold;
        font-size: 16px;
        line-height: 1.1em;
        text-transform: uppercase;
      }
      &.start_time,
      &.end_time {
        width: 12.5%;
      }
      &.flex {
        width: 15%;
        display: flex;
        flex-flow: row nowrap;
        .form-field {
          width: 50%;
        }
      }
      &.driving {
        width: 15%;
        display: flex;
        flex-flow: row nowrap;
        .form-field {
          width: 50%;
        }
      }
      &.children {
        width: 15%;
      }
      &.provider {
        width: 15%;
      }
      &.cancel {
        width: 50px;
        position: absolute;
        right: 0;
        top: 20px;
        text-align: center;
        height: 60px;
        margin-bottom: 8px;
      }
    }
    .form-field {
      margin-bottom: 0;
      &.all_children {
        margin-bottom: 4px;
      }
      &.selected_children {
        label.title {
          display: none;
        }
        .avatar {
          display: none;
        }
        .label {
          width: 100%;
          display: flex;
          padding-left: 0;
          .checkbox {
            order: 1;
          }
          p {
            order: 2;
            font-weight: 400;
          }
        }
        .checkbox-group {
          width: 100%;
        }
      }
    }
    .radio-group {
      display: inline-block;
      margin-right: 8px;
    }
  }
  .btn-wrapper {
    margin-top: 16px;
  }
  .cancel-btn {
    appearance: none;
    display: inline-block;
    width: 25px;
    height: 25px;
    background-color: $error-red;
    margin-top: 18px;
    border: 0;
    border-radius: 50%;
    span {
      @include font-header(400);
      color: #fff;
      font-size: 11px;
      line-height: 1.1em;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.availability-selector {
  width: 100%;
  margin-bottom: 40px;
  .error {
    color: $error-red;
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }
  .availability {
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: stretch;
    margin: auto;
    h3 {
      @include font-body(700);
      width: 100%;
      font-size: 16px;
      line-height: 1.5em;
      margin-bottom: 5px;
      letter-spacing: 0.1em;
      color: $dark-grey;
      text-transform: none;
      border-bottom: 0;
    }
    + .availability {
      margin-top: 20px;
    }
  }
  .option {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    width: 32%;
    background-color: $error-red;
    padding: 4px 6px 4px 37px;
    text-decoration: none !important;
    border-radius: 4px;
    &:before {
      content: '\f00d';
      font-family: 'Font Awesome 5 Pro';
      position: absolute;
      left: 11px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 18px;
      color: #fff;
    }
    &.active {
      background-color: $primary-green;
      &:before {
        content: '\f00c';
        left: 10px;
      }
    }
    p {
      @include font-body(700);
      font-size: 14px;
      line-height: 1.5em;
      letter-spacing: 0;
      color: #fff;
      margin-bottom: 0;
      &.small {
        @include font-body(400);
      }
    }
  }
}

.modal-time-slot,
.modal-week-days {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .form-field-wrapper {
    width: 175px;
    padding: 10px;
  }

  .day {
    cursor: pointer;
    width: 40px;
    height: 40px;
    background: #eeeeee;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: #6c7d8e;
    border-radius: 25px;
    margin: 7px;

    &.selected {
      background: #26ccbb;
      color: #fff;
    }
  }
}

.error {
  color: red;
}

@media screen and (max-width: $breakpoint-lg) {
  main.availability {
    .content {
      padding: 0;
    }
  }
  .sitter-availability {
    .calendar-view {
      .calendar-wrapper {
        max-width: 100%;
        width: 500px;
        margin: auto;
      }
      .calendars {
        .calendar {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-sm) {
  .availability-selector .option {
    padding-left: 6px;
    padding-top: 24px;
    text-align: center;
    &:before {
      left: 50% !important;
      top: 2px;
      transform: translateX(-50%);
    }
    p {
      width: 100%;
      text-align: center;
    }
  }
  .sitter-availability {
    .availability-row {
      padding-left: 110px;
      .add-time-slot {
        font-size: 14px;
        width: 160px;
      }
      .form-field-wrapper {
        &.date {
          width: 110px;
          top: 43px;
          font-size: 14px;
        }
      }
      .timeslot {
        width: 210px;
        .slot {
          font-size: 14px;
        }
      }
    }
  }
  .modal-time-slot,
  .modal-week-days {
    .day {
      margin-left: 2px;
      margin-right: 2px;
      width: 36px;
      height: 36px;
      font-size: 9px;
    }
  }
}
