.appointment-detail-container {
  position: fixed;
  top: 0;
  left: -$apptWidth;
  width: $apptWidth;
  height: 100vh;
  background-color: $light-taupe;
  z-index: 5;
  transition: $apptTransitionProps;

  &.with-time-change {
    .appointment-detail-content {
      height: calc(100% - 170px);
    }
  }
  &.with-appt-actions {
    .appointment-detail-content {
      height: calc(100% - 75px);
    }
  }

  .close-detail {
    position: absolute;
    top: 12px;
    right: 30px;
    z-index: 5;
    i.fas {
      font-size: 34px;
      color: $slate-blue;
    }

    button {
      background: none;
      border: none;
    }
  }

  body.appointment-open & {
    left: 0;
    .appt-detail-actions {
      left: 0;
    }
  }

  .appointment-detail-content {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .appt-section {
    width: 50%;
  }

  .family-info,
  .sitter-info,
  .appt-date {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    min-height: 400px;
  }

  .family-info {
    background: $gradient-grey;
    text-align: center;

    .family {
      .avatar {
        width: 250px;
        height: 250px;
        margin: auto;
        .avatar-wrapper {
          border: 5px solid #fff;
        }
        img {
          width: 100%;
          height: auto;
        }
      }
      h2 {
        @include font-body(700);
        font-weight: bold;
        font-size: 36px;
        line-height: 2em;
      }
      p {
        font-size: 16px;
        line-height: 1.5em;
        margin-bottom: 20px;
        i.fas {
          color: $primary-green;
        }
      }
    }
  }
  .appt-date {
    background: $gradient-salmon;
    text-align: center;
    h4 {
      text-transform: uppercase;
      font-size: 12px;
      line-height: 1.1em;
      margin-bottom: 32px;
    }
    h2 {
      @include font-header(700);
      text-transform: uppercase;
      font-size: 24px;
      line-height: 1.1em;
      letter-spacing: 0.1em;
      margin-bottom: 2px;
    }
    h3 {
      font-size: 18px;
      line-height: 1.1em;
      margin-bottom: 30px;
    }
    .appt-actions {
      text-align: center;
    }
    .theme-btn {
      width: auto;
      display: inline-block;
      + .theme-btn {
        margin-left: 16px;
      }
    }
  }
  .sitter-info {
    background: $gradient-grey;
    text-align: center;
    .sitters {
    }
    .sitter {
      .avatar {
        width: 150px;
        height: 150px;
        margin: auto;
        .avatar-wrapper {
          border: 5px solid #fff;
        }
        img {
          width: 100%;
          height: auto;
        }
      }
      h3 {
        font-size: 11px;
        line-height: 1.5em;
        color: $slate-blue;
        margin-top: 10px;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        margin-bottom: 0;
      }
      h2 {
        font-weight: bold;
        font-size: 28px;
        line-height: 1.5em;
        letter-spacing: 0.05em;
      }
      p {
        color: $slate-blue;
        font-size: 16px;
        line-height: 1.5em;
        margin-bottom: 20px;
        cursor: pointer;
        i.fas {
          color: $primary-green;
        }
      }
      .sitter-call {
        a.theme-btn {
          display: inline-block;
        }
      }
    }
  }

  .title {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    padding-bottom: 20px;
    i.fas {
      color: $primary-green;
      font-size: 11px;
    }
    h2 {
      font-size: 11px;
      line-height: 1.1em;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }
  }
  .info {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding-bottom: 50px;
    i.fas {
      font-size: 18px;
      color: $dark-grey;
    }
    h4 {
      @include font-body();
      text-transform: uppercase;
      color: $slate-blue;
      font-size: 11px;
      margin-bottom: 0;
      line-height: 1.25em;
      letter-spacing: 0.1em;
    }
    p {
      @include font-body(700);
      letter-spacing: 0.1em;
      color: $dark-grey;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 1.25em;
      margin-bottom: 0;
      &.info-amt {
        color: $slate-blue;
        font-size: 11px;
      }
    }
  }
  .appt-info {
    background: $gradient-grey;
    padding: 36px 6%;
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    justify-content: flex-start;

    &.sitter {
      width: 100% !important;
      .info-section {
        width: 25%;
        padding-right: 40px;
      }
    }
    .info-col {
      width: 25%;
      padding-right: 1%;
      opacity: 0.3;
      padding-top: 10px;
      &.active {
        opacity: 1;
      }
      &.slate-blue {
        p {
          color: $brand-blue;
        }
        i.far {
          color: $brand-blue;
        }
      }
    }
    .notes {
      p {
        font-size: 14px;
        line-height: 1.25em;
        letter-spacing: 0;
      }
    }
    .medical {
      tr {
        padding-bottom: 12px;
      }
      td {
        vertical-align: top;
      }
      p {
        font-size: 14px;
        line-height: 1.25em;
        letter-spacing: 0;
        margin-bottom: 0;
        &.name {
          font-weight: 700;
        }
      }
    }
    .appt-actions {
      width: 100%;
      margin-top: 24px;
      text-align: center;
    }
  }
  .child-info {
    background: linear-gradient(114.62deg, #fbfeff 7.9%, #ffffff 89.94%);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
    border: 1px solid #eee;

    &.sitter-view {
      width: 100% !important;
      display: flex;
      flex-flow: row wrap;
      align-items: stretch;
      .child {
        width: 50%;
        display: block;
        border-right: 1px solid #eee;
        display: flex;
        flex-flow: row wrap;
        align-items: flex-start;
        .left {
          width: 125px;
        }
        .right {
          width: calc(100% - 125px);
        }
      }
      .avatar {
        width: 125px;
        height: 125px;
        padding-right: 20px;
        padding-bottom: 20px;
        float: none;
      }

      i.fas {
        color: $primary-green;
        font-size: 11px;
      }
      h4 {
        font-size: 11px;
        line-height: 1.1em;
        letter-spacing: 0.1em;
        margin-top: 26px;
        text-transform: uppercase;
      }
      p {
        font-size: 14px;
        line-height: 1.25em;
        letter-spacing: 0.1em;
        margin-bottom: 0;
      }
      .child-mobile-set {
        display: none;
      }
    }
  }
  .child {
    padding: 25px 30px;
    border-bottom: 1px solid #eee;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    .avatar {
      width: 100px;
      height: 100px;
      float: left;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .name {
      padding-left: 16px;
      float: left;
      width: calc(100% - 220px - 100px);
    }
    h3 {
      @include font-header(700);
      font-size: 16px;
      line-height: 1.25em;
      margin-bottom: 0;
    }
    p {
      font-size: 16px;
      line-height: 1.25em;
      color: $slate-blue;
      margin-bottom: 0;
    }
  }
  .healthy-sick-toggler {
    width: 220px;
    height: 50px;
    overflow: hidden;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    background-color: #fff;
    &.healthy {
      &:before {
        left: 0;
        top: 0;
        background-color: $primary-green;
      }
      .hs-btn.healthy button {
        color: #fff;
        i.fas {
          color: #fff;
        }
      }
    }
    &.sick {
      &:before {
        left: 50%;
        top: 0;
        background-color: #f48693;
      }
      .hs-btn.sick button {
        color: #fff;
        i.fas {
          color: #fff;
        }
      }
    }
    &:before {
      position: absolute;
      left: 0;
      top: 0;
      content: '';
      width: 50%;
      height: 100%;
      transition: all 0.25s ease;
    }
    .hs-btn {
      height: 100%;
      width: 50%;
      float: left;
      &.healthy {
        i.fas {
          color: $primary-green;
        }
      }
      &.sick {
        i.fas {
          color: #f48693;
        }
      }
    }
    button {
      @include font-body();
      text-align: center;
      text-transform: uppercase;
      border: 0;
      appearance: none;
      font-size: 12px;
      line-height: 1.25em;
      background-color: transparent;
      width: 100%;
      height: 100%;
      transition: all 0.25s ease;
      i.fas {
        vertical-align: sub;
        font-size: 22px;
        transition: all 0.25s ease;
        margin-right: 9px;
      }
    }
  }
  .appt-detail-actions {
    position: fixed;
    bottom: 0;
    left: -$apptWidth;
    z-index: 999;
    width: $apptWidth;
    text-align: center;
    background: $x-light-grey;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.05);
    padding: 20px 0;
    transition: $apptTransitionProps;
    h2 {
      text-transform: uppercase;
      color: $primary-orange;
    }
    p {
      color: $primary-orange;
      margin-bottom: 0;
      font-size: 16px;
      line-height: 1.5em;

      + .btn-wrapper {
        margin-top: 12px;
      }
    }
    .button-row {
      margin-top: 10px;
    }
    .warning-text {
      margin: 10px 10px 0;
      text-align: left;
    }

    .bonus-info {
      margin: 10px auto 0;
      max-width: 200px;
    }
  }
}

@media screen and (max-width: $breakpoint-md) {
  .appointment-detail-container {
    left: -100%;
    width: 100%;
    .appt-detail-actions {
      width: 100%;
      left: -100%;
    }
    .appointment-detail-content {
      &.child-open {
        overflow: hidden;
      }
    }
    .appt-section {
      width: 100%;
      padding-top: 30px;
      padding-bottom: 30px;
      min-height: 0 !important;
      .appt-section-inner {
        width: 100%;
      }
    }
    .title {
      padding-bottom: 8px;
    }
    .appt-info {
      .info-col {
        text-align: center;
        padding-left: 1%;
      }
      &.sitter {
        .info-section {
          width: 100% !important;
          padding-right: 0;
          padding-bottom: 36px;
        }
      }
    }
    .appt-date {
      h2 {
        font-size: 20px;
        margin-bottom: 0;
      }
      h3 {
        font-size: 16px;
      }
    }
    .sitter-info {
      text-align: left;
      padding-left: 15px;
      padding-right: 15px;
      .sitter {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        h2 {
          font-size: 20px;
        }
        .avatar {
          width: 60px;
          height: 60px;
          i {
            font-size: 24px;
          }
        }
        .sitter-deets {
          width: calc(100% - 60px - 120px);
          padding: 0 10px;
        }
        .sitter-call {
          width: 120px;
        }
      }
    }
    .child-mobile-profile {
      position: fixed;
      width: 375px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 100%;
      max-height: 545px;
      overflow-y: scroll;
      text-align: center;
      background-color: #fff;
      border: 1px solid #eeeeee;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
      z-index: 5;
      .inner {
        width: 100%;
        height: 100%;
        padding: 25px;
      }
      .avatar {
        width: 125px;
        height: 125px;
        margin: auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
      i.fas {
        color: $primary-green;
        font-size: 14px;
      }
      h3 {
        @include font-header(700);
        font-size: 20px;
        line-height: 1.5em;
        margin: 9px 0 0;
      }
      h4 {
        font-size: 11px;
        line-height: 1.1em;
        letter-spacing: 0.1em;
        margin-top: 26px;
        text-transform: uppercase;
        color: $slate-blue;
      }
      p {
        font-size: 14px;
        line-height: 1.25em;
        letter-spacing: 0.1em;
        margin-bottom: 0;
        &.slate-blue {
          color: $slate-blue;
        }
      }
      .close-modal {
        position: absolute;
        bottom: 25px;
        left: 50%;
        transform: translateX(-50%);
        i.fas {
          font-size: 26px;
        }
      }
    }
    .child-info {
      display: flex;
      flex-flow: row wrap;
      padding-top: 0;
      padding-bottom: 0;
      &.sitter-view {
        .child {
          .left {
            width: 60px;
          }
          .right {
            padding-left: 10px;
            width: calc(100% - 60px);
          }
        }
        .avatar {
          width: 60px;
          height: 60px;
          padding: 0;
          i {
            font-size: 18px;
          }
        }
        .child-desktop-profile {
          display: none;
        }
        .child-mobile-set {
          display: block;
          position: absolute;
          right: 10px;
          bottom: 16px;
          i.fas {
            font-size: 14px;
            color: $primary-green;
          }
        }
      }
    }
    .child {
      width: 50%;
      flex-flow: row wrap;
      border-right: 1px solid #eee;
      padding: 30px 4%;
      .avatar {
        width: 60px;
        height: 60px;
        margin-bottom: 13px;
        i {
          font-size: 18px;
        }
      }
      .name {
        width: calc(100% - 60px);
      }
      .healthy-sick-toggler-wrapper {
        width: 100%;
      }
    }
    .healthy-sick-toggler {
      width: 170px;
      height: 40px;
      button {
        padding: 0;
        i.fas {
          font-size: 18px;
          margin-right: 7px;
        }
      }
    }
  }
}
