main.program-detail {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  .mobile-header {
    display: none;
  }
  .program-inner {
    padding: 30px;
    width: calc(100% - 315px);
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .program-instructions {
    margin-left: -30px;
    margin-right: -30px;
    background-color: $slate-blue;
    padding: 20px 0;
    margin-top: 22px;
    text-align: center;
    h3 {
      @include font-header(700);
      color: #fff;
      font-size: 18px;
      line-height: 1.1em;
      margin-bottom: 0;
    }
  }
  .program-calendar {
    margin-top: 40px;
    margin-bottom: 20px;
    .arrow {
      position: absolute;
      z-index: 5;
      top: -4px;
      appearance: none;
      outline: 0;
      border: 0;
      background-color: transparent;
      i {
        color: $slate-blue;
      }
      &.prev {
        left: 0;
      }
      &.next {
        right: 0;
      }
      &.disabled {
        i {
          color: #d8d8d8;
        }
        cursor: not-allowed;
        transition: all 0.25s ease;
      }
    }
    .calendars {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      justify-content: space-between;
      .calendar {
        width: 31%;
        margin: 0;

        h5 {
          text-transform: uppercase;
        }
      }
    }
  }
  .program-appointments {
    display: table;
    width: 100%;
  }
  .program-appointment {
    display: table-row;
  }
  .program-col {
    display: table-cell;
    padding-right: 75px;
    padding-top: 23px;
    padding-bottom: 23px;
    border-bottom: 1px solid #d2d2d2;
    vertical-align: middle;
    &.signup {
      padding-right: 25px;
    }
    h4 {
      @include font-body(400);
      margin-bottom: 7px;
      font-weight: 400;
      font-size: 11px;
      line-height: 1.1em;
      letter-spacing: 0;
      color: $slate-blue;
      text-transform: none;
      &.section-label {
        cursor: pointer;
      }
    }
    p {
      font-size: 16px;
      letter-spacing: 0;
      margin-bottom: 0;
      line-height: 1.1em;
    }
  }
  .program-sign-up {
    appearance: none;
    background-color: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    width: 30px;
    height: 30px;
    text-align: center;
    i {
      font-size: 30px;
      line-height: 30px;
    }
    &.available {
      i {
        color: $primary-green;
      }
    }
    &.unavailable {
      i {
        color: $error-red;
      }
    }
  }
  .program-sidebar {
    background-color: #f5f5f5;
    overflow-x: hidden;
    overflow-y: auto;
    .sidebar-actions {
      padding-bottom: 31px;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      button + button {
        margin-top: 10px;
      }
    }
    .sidebar-family-info {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      margin-bottom: 30px;
      .avatar {
        width: 100px;
        height: 100px;
      }
      .info {
        width: calc(100% - 100px);
        padding-left: 18px;
        h3 {
          @include font-body(700);
          font-size: 18px;
          line-height: 1.5em;
          margin-bottom: 0;
          letter-spacing: 0;
        }
        p {
          font-size: 14px;
          line-height: 1.5em;
          margin-bottom: 3px;
          letter-spacing: 0;
        }
        h5 {
          @include font-body(700);
          color: $slate-blue;
          font-size: 12px;
          line-height: 1.25em;
          letter-spacing: 0;
          margin-bottom: 0;
        }
      }
    }
    .info-section {
      margin-bottom: 30px;
      h4 {
        @include font-body(700);
        font-size: 12px;
        line-height: 1.25em;
        letter-spacing: 0;
        color: $slate-blue;
        margin-bottom: 6px;
      }
      p {
        font-size: 14px;
        line-height: 1.25em;
        letter-spacing: 0;
        margin-bottom: 0;
      }
      &.cancel {
        p {
          color: $slate-blue;
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
    .communications {
      .form-field textarea {
        min-height: 0;
      }
      .communication-item {
        margin-top: 20px;
      }
      p {
        font-size: 13px;
        line-height: 1.5em;
        margin-bottom: 4px;
        letter-spacing: 0;
        &.info {
          font-style: italic;
          font-size: 11px;
          line-height: 1.1em;
          margin-bottom: 0;
          color: $slate-blue;
          letter-spacing: 0.1em;
        }
      }
    }
  }
  .sitter-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    .avatar {
      width: 60px;
      height: 60px;
    }
    .info {
      width: calc(100% - 60px);
      padding-left: 8px;
      h3 {
        @include font-body(700);
        font-size: 18px;
        line-height: 1.5em;
        margin-bottom: 0;
        letter-spacing: 0;
      }
    }
    p {
      display: flex;
      width: 100%;
    }
  }
}

.program-sitter-submission-modal {
  p {
    text-align: left;
    letter-spacing: 0;
  }
  .form-field {
    label {
      text-align: left;
    }
  }
}

.program-fee-form {
  text-align: center;
  p.booking-fee {
    @include font-body(700);
    font-size: 28px;
    line-height: 1.1em;
    letter-spacing: 0.1em;
    margin-bottom: 0;
    text-transform: uppercase;
  }
  p.coupon-info {
    font-size: 24px;
    line-height: 1.1em;
    letter-spacing: 0;
    margin-bottom: 0;
    + p.booking-fee {
      margin-top: 8px;
    }
  }
  .payment-info-row {
    margin: 0 auto 22px;
  }
  .form-field button.theme-btn {
    max-width: none;
    padding: 17px 15px;
    width: auto;
  }
  .coupon-checker-component {
    text-align: left;
    width: 100%;
    max-width: 320px;
    margin: 32px auto 24px;
    .coupon-checker-wrapper {
      flex-flow: row wrap;
      justify-content: center;
      .form-field {
        width: 100%;
        input {
          margin-bottom: 10px;
          text-align: left;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-lg) {
  main.program-detail {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    .mobile-header {
      width: 100%;
      display: block;
      > h4.section-label {
        padding: 20px 15px;
      }
      .mobile-tab-selection {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
        .mobile-tab {
          width: 50%;
          text-align: center;
          padding-bottom: 7px;
          i {
            font-size: 14px;
            color: $slate-blue;
          }
          h3 {
            @include font-body(700);
            font-size: 14px;
            color: $slate-blue;
            line-height: 1.1em;
            letter-spacing: 0.1em;
            margin-bottom: 0;
            text-transform: uppercase;
          }
          hr {
            width: 100%;
            height: 1px;
            background-color: #e5e5e5;
            border: 0;
            margin: 0;
            position: absolute;
            top: 100%;
            left: 0;
            transform: translateY(-50%);
          }
          &.active {
            i,
            h3 {
              color: $primary-green;
            }
            hr {
              background-color: $primary-green;
              height: 5px;
            }
          }
        }
      }
    }
    .program-inner {
      width: 100%;
      height: auto;
      overflow-y: hidden;
      > h4.section-label {
        display: none;
      }
    }
    .program-sidebar {
      width: 100%;
      height: auto;
      overflow-y: hidden;
    }
    .program-calendar .calendars .calendar {
      width: 100%;
    }
    .program-appointments {
      display: block;
    }
    .program-appointment {
      display: flex;
      flex-flow: row wrap;
      align-items: stretch;
      justify-content: flex-start;
      padding: 20px 0;
      border-bottom: 1px solid #d2d2d2;
    }
    .program-col {
      display: block;
      flex: 1 0 auto;
      padding: 0 0 16px;
      margin-right: 20px;
      border: 0 !important;
      &.sitter {
        padding-bottom: 0;
      }
      p {
      }
    }
    .sitter-wrapper {
      .avatar {
        width: 40px;
        height: 40px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-md) {
  main.program-detail {
    .program-col {
      h4 {
        font-size: 10px;
      }
      p {
        font-size: 14px;
      }
    }
    &.family {
      .program-calendar {
        margin-top: 0;
      }
    }
    .sitter-wrapper {
      .avatar {
        width: 33px;
        height: 33px;
      }
      .info {
        width: calc(100% - 33px);
        h3 {
          font-size: 15px;
        }
      }
    }
  }
}
