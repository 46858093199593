.sit-log {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  h2 {
    color: $brand-blue;
  }
  h4.section-label {
    margin-top: 36px;
    margin-bottom: 12px;
  }
}

.sitter-sit-log-form-wrapper {
  max-width: 950px;
  width: 100%;
  margin: auto;
  padding: 40px 15px;

  .sitter-sit-log-form {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-between;
    .sit-log-section {
      width: 100%;
      margin-bottom: 20px;
      &.naptime,
      &.bedtime {
        width: 47.5%;
      }
    }

    p {
      font-size: 16px;
      margin-bottom: 0.5rem;
      line-height: 1.2em;
      span.required {
        color: $error-red;
      }
      span.small {
        font-size: 12px;
      }
    }

    .food-details {
      margin-top: 12px;
      .form-field-group {
        margin-bottom: 16px;
      }
      .form-field {
        margin-bottom: 0 !important;
        &.checkbox {
          label {
            font-size: 16px;
            vertical-align: baseline;
          }
          + .form-field.textarea {
            margin-top: 10px;
          }
        }
      }
    }
  }
}

.family-view-sit-log {
  max-width: 950px;
  width: 100%;
  margin: auto;
  padding: 40px 15px;
}

@media screen and (min-width: $breakpoint-lg) {
  .sitter-sit-log-form-wrapper {
    .sitter-sit-log-form {
      .sit-log-section {
      }
    }
  }
}
