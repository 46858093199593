.Toastify__toast-container {
  width: 75%;
  max-width: 600px;
  z-index: 1000000;
}

.Toastify__toast {
  box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  padding: 17px 13px;
  z-index: 10000000;
  &.Toastify__toast--success {
    background-color: $primary-green;
    .Toastify__toast-body {
      &:before {
        content: '\f164';
      }
    }
    .Toastify__close-button {
      &:before {
        color: #128175;
      }
    }
  }
  &.Toastify__toast--error {
    background-color: $primary-coral;
    .Toastify__toast-body {
      &:before {
        content: '\f57a';
      }
    }
    .Toastify__close-button {
      &:before {
        color: #a8353c;
      }
    }
  }
  .Toastify__toast-body {
    @include font-body(700);
    color: #fff;
    font-size: 13px;
    line-height: 1.25em;
    z-index: 10000000;
    &:before {
      font-family: 'Font Awesome 5 Pro';
      color: #fff;
      font-size: 18px;
      vertical-align: top;
      margin-right: 18px;
    }
  }
  .Toastify__close-button {
    opacity: 1;
    svg {
      display: none;
      visibility: hidden;
      opacity: 0;
    }
    &:before {
      content: '\f00d';
      font-family: 'Font Awesome 5 Pro';
      font-size: 18px;
      vertical-align: top;
    }
  }
}

@media screen and (max-width: $breakpoint-md) {
  .Toastify__toast-container {
    width: 80%;
    left: 10%;
    bottom: 24px;
    transform: none;
    z-index: 10000000;
  }
}
