.appointment-calendar {
  padding-top: 30px;
  width: 100%;
  height: 100%;
  text-transform: uppercase;
  line-height: 17px;
  letter-spacing: 0.1em;

  h4 {
    color: $primary-green;
    font-weight: bold;
    font-size: 16px;
  }

  .header {
    margin-bottom: 20px;

    .icon {
      margin-right: 5px;

      &.appointment {
        color: #2c2c2c;
      }

      &.holiday {
        color: #26ccbb;
      }

      &.day {
        color: #a75eeb;
      }
    }

    .calendar-key {
      margin: 10px 5px;

      span {
        padding: 10px 5px;
        font-size: 11px;
      }
    }
  }
}

.calendar {
  margin: 40px;

  h5 {
    font-weight: bold;
    font-size: 16px;

    &.january {
      color: $color-jan;
    }
    &.february {
      color: $color-feb;
    }
    &.march {
      color: $color-mar;
    }
    &.april {
      color: $color-apr;
    }
    &.may {
      color: $color-may;
    }
    &.june {
      color: $color-jun;
    }
    &.july {
      color: $color-jul;
    }
    &.august {
      color: $color-aug;
    }
    &.september {
      color: $color-sep;
    }
    &.october {
      color: $color-oct;
    }
    &.november {
      color: $color-nov;
    }
    &.december {
      color: $color-dec;
    }
  }

  table {
    thead {
      tr {
        font-size: 9px;
        th {
          text-transform: uppercase;
        }
      }
    }
    tbody {
      tr {
        td.calendar-day {
          width: 55px;
          height: 60px;
          background-color: #fff;
          border: 1px solid #eeeeee;
          box-sizing: border-box;
          color: $slate-blue;

          span.day {
            @include font-header(400);
            position: absolute;
            top: 5px;
            left: 5px;
            font-size: 11px;
            color: currentColor;
          }

          i.with-tooltip {
            span.time-tooltip {
              display: none;
              position: absolute;
              top: 100%;
              left: 50%;
              transform: translateX(-50%);
              background-color: $brand-blue;
              color: #fff;
              @include font-header(400);
              padding: 9px 5px;
              width: 148px;
              z-index: 100000;
              font-size: 12px;
              letter-spacing: 0.1em;
              line-height: 1.25em;
              span {
                display: block;
                width: 100%;
              }
            }
            &:hover {
              span.time-tooltip {
                display: block;
              }
            }
            &.available {
              color: $primary-green;
            }
            &.unavailable {
              color: $error-red;
            }
          }

          &.empty {
            background-color: #fafafa;
          }

          &.today {
            background-color: #b26af5;
            color: #fff;
          }

          &.past {
            opacity: 0.3;
          }
          &.future {
            opacity: 0.3;
          }

          &.selected {
            background-color: $primary-green;
            color: #fff;
            &:before {
              content: '';
              content: '\f736';
              font-family: 'Font Awesome 5 Pro';
              font-size: 24px;
              position: absolute;
              top: 60%;
              left: 50%;
              transform: translate(-50%, -50%);
              color: #fff;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-md) {
  .appointment-calendar {
    padding-top: 20px;
    h4 {
      display: none;
    }
    .view-full-btn {
      display: none;
    }
    .calendar {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
