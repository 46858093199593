.notice-container {
  padding: 30px 30px 0;
}

.notice {
  padding: 15px;
  background: linear-gradient(113.49deg, #f5ebff 7%, #efe7f5 86.24%);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  &:nth-of-type(n + 2) {
    margin-top: 16px;
  }
  .close-notice {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 2;
    display: block;
    i.far {
      color: $slate-blue;
      font-size: 22px;
    }
  }
  h3 {
    text-transform: uppercase;
    font-size: 11px;
    line-height: 1.1em;
    letter-spacing: 0.1em;
    i.fas {
      font-size: 13px;
    }
  }
  h2 {
    @include font-body(700);
    font-size: 16px;
    line-height: 1.1em;
    letter-spacing: 0.1em;
  }
  p {
    font-size: 13px;
    line-height: 1.5em;
    letter-spacing: 0.1em;
    margin-bottom: 0;
  }
  &.orange {
    background: #fd9742;
    h3,
    h2,
    p {
      color: #fff;
    }
  }
}

@media screen and (max-width: $breakpoint-md) {
  .notice-container {
    padding: 0;
    width: 100%;
  }
  .notice {
    border-radius: 0;
  }
}
