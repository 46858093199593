// this will contain styles for both sitter and family profiles

.profile {
  &.sitter {
  }
  &.family {
  }
  &.availability {
    .profile-body {
      margin: 0px;
      padding: 40px 0px;
      width: 100%;
      max-width: 1200px;
      margin-left: auto;
      margin-right: auto;
      text-align: left;
      h2 {
        text-transform: capitalize;
        color: $primary-green;
      }
    }
  }
  &.child,
  &.pet,
  &.settings,
  &.sitter-public {
    .profile-header {
      h2 {
        margin-bottom: 0;
      }
      .avatar {
        cursor: pointer;
        &:before {
          content: '\F030';
          font-family: 'Font Awesome 5 Pro';
          font-weight: 400;
          font-size: 14px;
          height: 30px;
          width: 30px;
          line-height: 30px;
          text-align: center;
          background-color: $brand-blue;
          color: #fff;
          position: absolute;
          z-index: 3;
          bottom: 0;
          left: 66%;
          border-radius: 50%;
        }
      }
    }
  }
  &.settings {
    h3 {
      width: 100%;
      font-weight: 700;
      color: $slate-blue;
      font-size: 12px;
      line-height: 1.1em;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      margin-bottom: 26px;
      padding-bottom: 4px;
      border-bottom: 1px solid $slate-blue;
    }
    .family-accounts-table {
      .fa-row {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
      }
      .fa-col {
      }
      p {
        font-size: 13px;
        line-height: 1.25em;
      }
    }
  }
  .profile-header {
    height: 360px;
    width: 100%;
    padding: 40px 0;
    text-align: center;
    background: $gradient-salmon;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
    }
    .inner {
      z-index: 3;
    }
    label {
      font-size: 0;
      line-height: 0;
      margin: 0;
    }
    .avatar {
      width: 200px;
      height: 200px;
      margin: auto;
      .avatar-wrapper {
        border: 5px solid #fff;
        background-color: #26ccbb;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 2;
      }
      i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 36px;
        line-height: 1em;
        color: #fff;
      }
    }
    h2 {
      line-height: 1.5em;
      margin: 6px 0 14px;
    }
    a {
      display: inline-block;
    }
  }
  .profile-body {
    max-width: 950px;
    width: 100%;
    margin: auto;
    padding: 40px 0;
    h2 {
      font-size: 22px;
      font-weight: 700;
      line-height: 1.1em;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: $brand-blue;
      margin-bottom: 24px;
    }
  }
  .profile-list {
    text-align: center;
    padding-bottom: 56px;
    &:nth-last-of-type(1) {
      padding-bottom: 0;
    }
    h4 {
      width: 100%;
      margin-bottom: 12px;
      text-align: left;
    }
    a.theme-btn {
      margin-top: 20px;
      display: inline-block;
    }
  }
  .profile-list-wrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    .list-item {
      width: 50%;
      border: 1px solid $x-light-grey;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
      background-color: $light-taupe;
      transition: all 0.25s ease;
      cursor: pointer;
      text-align: left;
      align-items: center;
      &:hover {
        background-color: $x-light-grey;
      }
      .inner {
        width: 100%;
        padding: 20px;
        display: flex;
        flex-flow: row nowrap;
        text-decoration: none !important;
        align-items: center;
      }
      .avatar {
        width: 60px;
        height: 60px;
        .avatar-wrapper {
          border: 4px solid #fff;
          background-color: $slate-blue;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          i {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 24px;
            line-height: 1em;
            color: #fff;
          }
        }
      }
      .bio {
        width: calc(100% - 60px);
        padding-left: 10px;
        h3,
        p {
          margin-bottom: 0;
        }
        i {
          color: $primary-green;
          font-size: 22px;
          position: absolute;
          top: 50%;
          left: 100%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  .links {
    padding-bottom: 56px;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    .link {
      display: block;
      width: 50%;
      border: 1px solid $x-light-grey;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
      background-color: $light-taupe;
      transition: all 0.25s ease;
      cursor: pointer;
      align-items: center;
      text-decoration: none !important;
      &:hover {
        background-color: $x-light-grey;
      }
      .inner {
        width: 100%;
        padding: 25px 25px 29px;
        display: flex;
        flex-flow: row nowrap;
        text-decoration: none !important;
        align-items: center;
      }
      .icon {
        width: 45px;
        height: 45px;
        i {
          font-size: 36px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: $primary-green;
        }
      }
      .text {
        width: calc(100% - 45px);
        padding-left: 19px;
        h3 {
          margin-bottom: 0;
        }
        i {
          color: $primary-green;
          font-size: 22px;
          position: absolute;
          top: 50%;
          left: 100%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  .cancel-btn {
    text-align: center;
    a {
      display: inline-block;
      margin-left: 16px;
      margin-right: 16px;
    }
  }
}

.profile-color-picker {
  margin-bottom: 24px;
  label {
    font-size: 11px;
    line-height: 1.1em;
    color: $slate-blue;
    margin-bottom: 10px;
  }
  .color-wrapper {
    display: flex;
    flex-flow: row nowrap;
  }
  .color-example {
    width: 26px;
    height: 26px;
    padding: 3px;
    border: 1px solid transparent;
    background-color: transparent !important;
    border-radius: 4px;
    margin-right: 12px;
    cursor: pointer;
    .inner {
      width: 100%;
      height: 100%;
      border-radius: 4px;
      overflow: hidden;
      background-color: currentColor;
    }
    &.active {
      border-color: currentColor;
    }
  }
}

.child-profile-form {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  .form-field {
    width: 100%;
    &.firstname,
    &.lastname,
    &.selected_medical_conditions {
      width: 47.5%;
    }
    &.firstname {
      margin-right: 5%;
    }
  }
  .form-field-group {
    &.dob {
      width: 47.5%;
      margin-right: 50%;
    }
  }
}

.self-recipient-delete-account-info {
  text-align: center;
  font-size: 14px;
  opacity: 0.9;
  margin-top: 20px;
}

.pet-profile-form {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: space-between;
  .form-field {
    width: 100%;
    &.name,
    &.type,
    &.other,
    &.age_years {
      width: 47.5%;
    }
    // &.name,
    // &.other {
    // 	margin-right: 5%;
    // }
  }
  .form-field-group {
    &.dob {
      width: 47.5%;
      margin-right: 50%;
    }
    &.pediatrician_info {
      width: 100%;
    }
  }
}

.sitter-private-profile-form {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  .form-field {
    width: 47.5%;
    &.address2 {
      width: 25%;
    }
    &.city,
    &.state,
    &.zip {
      width: 20%;
    }
    &.emergency_contact_name,
    &.firstname,
    &.address1,
    &.city,
    &.state,
    &.gender,
    &.marital_status {
      margin-right: 5%;
    }
    &.phone1,
    &.smoke,
    &.selected_medical_conditions {
      margin-right: 50%;
    }
    &.allergy_info {
      width: 100%;
    }
    .radio-group {
      display: inline-block;
      margin-right: 20px;
    }
  }
}

.sitter-public-profile-form {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  .divider {
    display: block;
    width: 100%;
    height: 1px;
    background-color: #c4c4c4;
    margin: 0 0 24px;
    border: 0;
  }
  .form-field {
    width: 100%;
    &.selected_age_ranges,
    &.selected_activities,
    &.vehicle_year,
    &.vehicle_make,
    &.vehicle_model,
    &.vehicle_seatbelts,
    &.languages {
      width: 47.5%;
    }
    &.selected_age_ranges,
    &.vehicle_year,
    &.vehicle_model,
    &.languages {
      margin-right: 5%;
    }
    .radio-group {
      display: inline-block;
      margin-right: 20px;
    }
  }
}

@media screen and (max-width: $breakpoint-lg) {
  .profile {
    &.availability {
      .profile-body {
        padding-left: 18px;
        padding-right: 18px;
        max-width: 900px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-md) {
  .profile {
    .profile-body {
      width: 100%;
      padding-left: 5%;
      padding-right: 5%;
    }
  }
}

@media screen and (max-width: $breakpoint-sm) {
  .profile {
    .profile-body {
      padding-left: 15px;
      padding-right: 15px;
    }
    .links {
      .link {
        width: 100%;
      }
    }
    .profile-list-wrapper {
      .list-item {
        width: 100%;
      }
    }
  }
  .sitter-public-profile-form,
  .sitter-private-profile-form,
  .pet-profile-form,
  .child-profile-form,
  .account-settings-form {
    .form-field {
      width: 100% !important;
      margin-right: 0 !important;
      &.address2,
      &.city,
      &.state,
      &.zip {
        width: 47.5% !important;
      }
      &.address2,
      &.state {
        margin-right: 5% !important;
      }
    }
    .form-field-group {
      width: 100% !important;
      margin-right: 0 !important;
    }
  }
}
