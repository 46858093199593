// Putting this here for now since tags are only used in programs
.tags-wrapper {
  &.filter {
    margin-bottom: 18px;
  }
  p {
    @include font-body(700);
    font-size: 12px !important;
    line-height: 1.25em;
    color: $slate-blue;
    margin-bottom: 2px;
  }
  .tag {
    display: inline-block;
    opacity: 0.5;
    background-color: $slate-blue;
    margin-right: 5px;
    border-radius: 20px;
    cursor: pointer;
    p {
      color: #fff;
      font-weight: 400;
      padding: 3px 7px;
      margin-bottom: 0;
    }
    &.solid {
      opacity: 1;
    }
    &.filter {
      cursor: pointer;
    }
  }
}

main.program-listing {
  width: 100%;
  height: 100%;

  .program-inner {
    padding-top: 30px;
    padding-bottom: 60px;
    padding-left: 8.75%;
    padding-right: 8.75%;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    > h4.section-label {
      margin-bottom: 30px;
    }
  }
  .program-listing-section {
    margin-bottom: 80px;
    > h4 {
      @include font-header(700);
      font-size: 14px;
      line-height: 1.25em;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      margin-bottom: 5px;
      color: $slate-blue;
    }
    &.pending {
      > h4 {
        margin-bottom: -15px;
      }
    }
  }
  .program-listing-wrapper {
    display: table;
    width: 100%;
  }
  .program-listing-row {
    display: table-row;
  }
  .program-listing-col {
    display: table-cell;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 50px;
    vertical-align: top;
    border-bottom: 1px solid #eeeeee;
    h4 {
      @include font-body(700);
      font-size: 12px;
      line-height: 1.25em;
      color: $slate-blue;
      letter-spacing: 0;
      margin-bottom: 6px;
    }
    p {
      font-size: 14px;
      line-height: 1.25em;
      letter-spacing: 0;
      margin-bottom: 0;
    }
    &.notes {
      width: 500px;
    }
    &.link {
      padding-right: 0;
    }
    &.tags {
      width: 200px;
    }
  }
  .family-inner {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    .avatar {
      width: 50px;
      height: 50px;
      i {
        font-size: 24px;
      }
    }
    .info {
      width: calc(100% - 50px);
      padding-left: 20px;
      p {
        @include font-body(400);
        font-size: 14px;
        line-height: 1.25em;
        letter-spacing: 0;
        margin-bottom: 5px;
      }
      h4 {
        margin-bottom: 0;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-lg) {
  main.program-listing {
    .program-listing-wrapper {
      display: block;
    }
    .program-listing-row {
      display: flex;
      flex-flow: row wrap;
      align-items: stretch;
      justify-content: flex-start;
      border-bottom: 1px solid #eeeeee;
      padding: 20px 0;
    }
    .program-listing-col {
      display: block;
      padding: 0 0 16px;
      margin-right: 20px;
      border: 0 !important;
      width: auto !important;
      flex: 1 0 auto;
      h4 {
        font-size: 10px;
      }
      &.notes {
        width: 100% !important;
      }
      &.link {
        width: 100% !important;
        padding-bottom: 0;
      }
    }
    .family-inner {
      .avatar {
        i {
        }
      }
      .info {
        p {
        }
      }
    }
  }
}
