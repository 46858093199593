$formLeftPadding: 10px;
$formFieldBottomMargin: 24px;

.form {
  .divider {
    display: block;
    width: 100%;
    height: 1px;
    background-color: #c4c4c4;
    margin: 0 0 $formFieldBottomMargin;
    border: 0;
  }
}

.form-field {
  margin-bottom: $formFieldBottomMargin;
  &.button {
    text-align: center;
  }
  &.password {
    margin-bottom: 3px;
    .password-toggle {
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
      z-index: 5;
      i {
        font-size: 20px;
        color: $slate-blue;
        line-height: 1.1em;
      }
    }
  }
  &.radio {
    label {
      font-size: 16px;
      line-height: 1.25em;
      margin-bottom: 0;
      vertical-align: top;
      width: 100%;
      span {
        color: $error-red;
      }
    }
  }
  input[type='text'],
  input[type='email'],
  input[type='password'],
  input[type='number'],
  textarea,
  select {
    position: relative;
    z-index: 1;
    width: 100%;
    background-color: #fff;
    appearance: none;
    border: 1px solid #d2d2d2;
    border-radius: 5px;
    font-size: 16px;
    padding: 24px $formLeftPadding 12px;
    outline: 0 !important;
    display: block;
    ~ label {
      @include font-body();
      transition: all 0.25s ease;
      position: absolute;
      color: $dark-grey;
      left: $formLeftPadding;
      top: 22px;
      z-index: 2;
      font-size: 16px;
      line-height: 1.1em;
      pointer-events: none;
      span {
        color: $error-red;
      }
    }
    &:focus,
    &:active {
      border-color: $color-may;
      ~ label {
        color: $color-may;
        font-size: 11px;
        top: 10px;
      }
    }
    &:disabled {
      color: $slate-blue;
      border: 1px solid #d2d2d2 !important;
      cursor: not-allowed;
      opacity: 0.7 !important;
      ~ label {
        color: $slate-blue !important;
      }
    }
    &.active {
      ~ label {
        color: $slate-blue;
        top: 10px;
        font-size: 11px;
      }
    }
    &.error {
      border-color: $error-red;
      ~ label {
        color: $error-red;
        // font-size: 11px;
        // top: 10px;
      }
    }
  }
  textarea {
    min-height: 150px;
  }
  select {
    background-image: url('/assets/images/icon-select-arrow.png');
    background-repeat: no-repeat;
    background-position: calc(100% - 10px);
    background-size: 15px;
  }
  input[type='radio'] {
    display: none;
    ~ label {
      span {
        color: $dark-grey;
      }
      .radio {
        width: 20px;
        height: 20px;
        margin-right: 6px;
        display: inline-block;
        background: #ffffff;
        border: 1px solid #eeeeee;
        box-sizing: border-box;
        border-radius: 50%;
        box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 0.25);
        vertical-align: middle;
      }
    }
    &:checked {
      ~ label .radio {
        &:before {
          content: '';
          border-radius: 50%;
          background: $slate-blue;
          border: 1px solid #eee;
          box-sizing: border-box;
          box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 0.25);
          height: 10px;
          width: 10px;
          position: absolute;
          top: 50%;
          left: 50%;
          margin-left: -5px;
          margin-top: -5px;
        }
      }
    }
  }
  input[type='checkbox'] {
    display: none;
    ~ label {
      font-size: 13px;
      line-height: 20px;
      margin-bottom: 0;
      .checkbox {
        display: inline-block;
        transition: all 0.25s ease;
        height: 20px;
        width: 20px;
        background: #ffffff;
        border: 1px solid #d2d2d2;
        border-radius: 4px;
        margin-right: 7px;
        vertical-align: sub;
        i {
          position: absolute;
          top: 50%;
          left: 50%;
          font-size: 12px;
          line-height: 1.1em;
          color: $color-may;
          transform: translate(-50%, -50%);
        }
      }
      span.required {
        color: $error-red;
      }
    }
    &:checked {
      ~ label {
        .checkbox {
          box-shadow: inset 0px 0px 3px rgba(0, 0, 0, 0.25);
        }
      }
    }
  }
  &.text-date-picker {
    @media screen and (min-width: $breakpoint-md) {
      // we only want to hide the spinner on desktop. not on mobile.
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      /* Firefox */
      input[type='number'] {
        -moz-appearance: textfield;
      }
    }

    .form-field-wrapper {
      position: relative;
      z-index: 1;
      width: 100%;
      background-color: #fff;
      border: 1px solid #d2d2d2;
      border-radius: 5px;
      font-size: 16px;
      padding: 24px $formLeftPadding 12px;
      outline: 0 !important;
      span {
        color: transparent;
        span {
          margin: 0 5px;
        }
      }
    }
    input {
      appearance: none;
      display: inline-block;
      font-size: 16px;
      padding: 0;
      border: 0;
      &.month {
        width: 20px;
      }
      &.day {
        width: 20px;
      }
      &.year {
        width: 40px;
      }
    }
    label {
      @include font-body();
      transition: all 0.25s ease;
      position: absolute;
      color: $dark-grey;
      left: $formLeftPadding;
      top: 22px;
      z-index: 2;
      font-size: 16px;
      line-height: 1.1em;
      pointer-events: none;
      span {
        color: $error-red;
      }
    }
    &.focus {
      .form-field-wrapper {
        border-color: $color-may !important;
      }
      label {
        color: $color-may !important;
        top: 10px;
        font-size: 11px;
      }
    }
    &.active {
      .form-field-wrapper {
        span {
          color: $dark-grey;
        }
      }
      label {
        color: $slate-blue;
        top: 10px;
        font-size: 11px;
      }
    }
  }

  &.child-checkbox {
    display: flex;
    flex-flow: row wrap;
    label.title {
      width: 100%;
      span {
        color: $error-red;
      }
    }
    .checkbox-group {
      width: 50%;
      padding-right: 2.5%;
      margin-bottom: 10px;
    }
    .inner {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
    }
    .avatar {
      width: 60px;
      height: 60px;
      .avatar-wrapper {
        border: 4px solid #fff;
        i {
          font-size: 16px;
        }
      }
    }
    .label {
      width: calc(100% - 60px);
      padding-left: 10px;
      p {
        @include font-header(700);
        font-size: 16px;
        line-height: 1.25em;
        margin-bottom: 0;
      }
    }
  }
  &.react-select,
  &.select-search {
    z-index: 20;
    &.focus,
    &.active {
      label {
        font-size: 11px;
        top: 10px;
      }
    }
    &.focus {
      label {
        color: $color-may !important;
      }
    }
    &.error {
      border-color: $error-red;
      label {
        color: $error-red;
        font-size: 11px;
        top: 10px;
      }
    }
    &.active {
      label {
        color: $slate-blue;
      }
    }

    label {
      @include font-body();
      transition: all 0.25s ease;
      position: absolute;
      color: $dark-grey;
      left: $formLeftPadding;
      top: 22px;
      z-index: 2;
      font-size: 16px;
      line-height: 1.1em;
      pointer-events: none;
      span {
        color: $error-red;
      }
    }
  }
  &.async-select {
    z-index: 20;
    &.focus,
    &.active {
      label {
        font-size: 11px;
        top: 10px;
      }
    }
    &.focus {
      z-index: 21;
      label {
        color: $color-may !important;
      }
    }
    &.error {
      border-color: $error-red;
      label {
        color: $error-red;
        font-size: 11px;
        top: 10px;
      }
    }
    &.active {
      label {
        color: $slate-blue;
      }
    }

    label {
      @include font-body();
      transition: all 0.25s ease;
      position: absolute;
      color: $dark-grey;
      left: $formLeftPadding;
      top: 22px;
      z-index: 2;
      font-size: 16px;
      line-height: 1.1em;
      pointer-events: none;
      span {
        color: $error-red;
      }
    }
  }
  &.datepicker {
    label {
      @include font-body();
      transition: all 0.25s ease;
      position: absolute;
      color: $dark-grey;
      left: $formLeftPadding;
      top: 22px;
      z-index: 2;
      font-size: 16px;
      line-height: 1.1em;
      pointer-events: none;
      span {
        color: $error-red;
      }
    }
    .react-datepicker__close-icon {
      position: absolute;
      z-index: 10;
      right: 30px;
      top: 50%;
      width: 40px;
      height: 40px;
      display: grid;
      place-items: center;
      transform: translateY(-50%);
      padding: 0;
    }
    .react-datepicker__close-icon::after {
      background-color: $slate-blue;
    }
    &.focus {
      label {
        color: $color-may !important;
        top: 10px;
        font-size: 11px;
      }
    }
    &.active {
      label {
        color: $slate-blue;
        top: 10px;
        font-size: 11px;
      }
    }

    .fa-calendar {
      position: absolute;
      z-index: 10;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      color: $slate-blue;
      pointer-events: none;
    }
  }
  &.file-upload {
    width: 100% !important;
    input {
      display: none;
    }
    label {
      display: block;
      span {
        color: $error-red;
      }
    }
    .file-field-instructions {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      margin-top: 2px;
      .avatar {
        width: 150px;
        height: 150px;
        cursor: pointer;
        .avatar-wrapper {
          border: 4px solid #fff;
          background: linear-gradient(141.8deg, #26ccbb 18.43%, #40e0d0 83.68%);
          box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          i {
            font-size: 50px;
            line-height: 1.5em;
            color: #fff;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
      .instructions {
        padding-left: 20px;
        width: calc(100% - 150px);
      }
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
      }
      p {
        font-size: 14px;
        line-height: 2em;
        margin: 0;
      }
    }
  }
  &.num-nights {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    label {
      span {
        color: $error-red;
      }
    }
    button {
      appearance: none;
      margin-left: 9px;
      padding: 0;
      border: 0;
      padding: 0;
      background-color: transparent;
      &.decrease {
        i {
          color: $error-red;
        }
      }
      &.increase {
        i {
          color: $brand-blue;
        }
      }
      &.disabled {
        i {
          color: #d2d2d2 !important;
        }
      }
      i {
        font-size: 32px;
        color: #fff;
      }
    }
  }
  &.activities-selector {
    label.title {
      display: block;
    }
    .checkbox-group-wrapper {
      margin-left: -7px;
      margin-right: -7px;
    }
    .checkbox-group {
      display: inline-block;
      &.inactive {
        .sitter-activity-tile .tile {
          background: #e9e9e9 !important;
          &:before {
            color: #949494 !important;
          }
          p {
            color: #949494 !important;
          }
        }
      }
      &.active {
        // opacity: 1;
      }
    }
  }
  &.coupon {
    margin-bottom: 10px;
  }
  .add-coupon-btn {
    margin-bottom: $formFieldBottomMargin;
  }
  span.error {
    color: $error-red;
    display: block;
    width: 100%;
    font-size: 12px;
  }
  span.word-count {
    position: absolute;
    z-index: 2;
    pointer-events: none;
    font-size: 11px;
    line-height: 1.1em;
    color: $slate-blue;
    bottom: 5px;
    right: 5px;
  }
  button[type='submit'] {
    @include font-body(700);
    width: 100%;
    max-width: 400px;
    font-size: 16px;
    letter-spacing: 0.1em;
    padding: 22px 0 22px;
    i {
      margin-right: 8px;
    }
  }
}

.tooltip-wrapper {
  position: absolute;
  top: 0;
  left: 100%;
  transform: translate(-50%, -50%);
  z-index: 2;
  i {
    color: $dark-grey;
    font-size: 16px;
    line-height: 1.1em;
    cursor: pointer;
    &:hover,
    &.active {
      + .tooltip-item {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  .tooltip-item {
    pointer-events: none;
    opacity: 0;
    visibility: 0;
    transition: all 0.25s ease;
    position: absolute;
    right: 100%;
    width: 220px;
    top: 50%;
    transform: translateY(-50%);
    padding: 10px 23px 10px 10px;
    // background-color: #fff;
    // box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
    // border: 1px solid #eee;
    background-image: url('/assets/images/tooltip-bg.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-origin: center;
    &:before {
      // content: "";
      width: 0;
      height: 0;
      z-index: 3;
      border-style: solid;
      border-width: 4.5px 0 4.5px 7.8px;
      border-color: transparent transparent transparent #fff;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
      position: absolute;
      left: 100%;
      top: 50%;
      transform: translateY(-50%);
    }
    p {
      font-size: 11px;
      line-height: 1.25em;
      margin-bottom: 0;
    }
  }
}

.form-field-group {
  &.field-with-checkbox {
    // 2 fields that are next to eachother, but independant fields within the form (e.g. DOB and "unborn" on register forms)
    width: 100%;
    margin-bottom: $formFieldBottomMargin;
    .form-field {
      margin-bottom: 0;
      + .form-field.boolean {
        width: 100%;
        margin-top: 10px;
      }
    }
  }
  &.field-with-hyperlink {
    width: 100%;
    margin-bottom: $formFieldBottomMargin;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    .form-field {
      margin-bottom: 0;
    }
    a {
      width: 100%;
      margin-top: 4px;
      font-size: 13px;
      line-height: 1.1em;
      text-decoration: underline;
    }
  }
}

.form-step-tracker {
  text-align: center;
  .inner {
    text-align: center;
    display: inline-block;
    &:before {
      content: '';
      width: calc(100% - 20px);
      height: 2px;
      background-color: $light-grey;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 19px;
    }
  }
  .form-step {
    z-index: 2;
    margin: 0 75px;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    .form-step-circle {
      width: 40px;
      height: 40px;
      margin: auto;
      border-radius: 50%;
      background-color: $light-grey;
    }
    h4 {
      font-size: 10px;
      margin: 2px 0 0;
      line-height: 1.1em;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: $slate-blue;
      position: absolute;
      top: calc(100% + 2px);
      left: 50%;
      transform: translateX(-50%);
    }
    &.completed {
      .form-step-circle {
        background-color: $color-may;
        i {
          position: absolute;
          top: 50%;
          left: 50%;
          font-size: 18px;
          line-height: 1.1em;
          transform: translate(-50%, -50%);
          color: #fff;
        }
      }
      h4 {
        color: $color-may;
      }
    }
    &.current {
      .form-step-circle {
        background-color: $brand-blue;
      }
    }
    &:nth-of-type(1) {
      margin-left: 0;
    }
    &:nth-last-of-type(1) {
      margin-right: 0;
    }
  }
}

.form-field-info {
  p {
    font-size: 12px;
    line-height: 1.1em;
    margin-bottom: 7px;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      margin-right: 15px;
      p {
      }
    }
  }
  i.fa-check {
    color: $color-may;
  }
  i.fa-times-circle {
    color: $error-red;
  }
}

.form-notice {
  background: rgba(244, 134, 147, 0.3);
  padding: 16px 36px;
  margin: 43px 0 0;
  border-radius: 4px;
  border: 1px solid #eee;
  h3 {
    font-size: 13px;
    font-weight: 700;
    line-height: 1.1em;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin-bottom: 5px;
  }
  p {
    font-size: 13px;
    line-height: 1.5em;
    letter-spacing: 0.1em;
    margin-bottom: 0;
  }
}

.recaptcha {
  div {
    margin: auto;
  }
}

@media screen and (max-width: $breakpoint-lg) {
}

@media screen and (max-width: $breakpoint-md) {
  .form-step-tracker {
    .form-step {
      margin: 0 65px;
    }
  }
  .form-notice {
    padding: 15px 20px;
  }
  .form-field {
    button[type='submit'] {
      width: 100%;
      max-width: 100%;
    }
    &.text-date-picker {
      input {
        &.month {
          width: 40px;
        }
        &.day {
          width: 40px;
        }
        &.year {
          width: 80px;
        }
      }
      .form-field-wrapper span span {
        margin: 0 15px 0 0;
      }
    }
    &.file-upload {
      .file-field-instructions {
        flex-flow: row wrap;
        .avatar {
          margin: auto;
        }
        .instructions {
          width: 100%;
          padding-left: 0;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-sm) {
  .form-step-tracker {
    .inner {
      &:before {
        top: 17px;
      }
    }
    .form-step {
      margin: 0 45px;
      .form-step-circle {
        width: 35px;
        height: 35px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-xs) {
  .form-step-tracker {
    .inner {
      &:before {
        top: 12px;
      }
    }
    .form-step {
      margin: 0 25px;
      .form-step-circle {
        width: 25px;
        height: 25px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-xxs) {
  .form-step-tracker {
    .form-step {
      margin: 0 20px;
    }
  }
}

@media screen and (max-width: 325px) {
  .form-step-tracker {
    .form-step {
      margin: 0 15px;
      h4 {
        font-size: 8px;
      }
    }
  }
}

@media screen and (min-width: $breakpoint-md) {
  .form-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px 5%;
  }
}

.form-inline-radio > label {
  margin-bottom: 10px;
}
.form-inline-radio .radio-group {
  display: inline-block;
  margin-right: 20px;
}
