.payment-fields {
  display: flex;
  flex-flow: row nowrap;
  .payment-field {
    width: 200px;
    margin-right: 50px;
    position: relative;
    z-index: 1;
    background-color: #fff;
    border: 1px solid #d2d2d2;
    border-radius: 5px;
    font-size: 16px;
    line-height: 1.1em;
    padding: 24px 10px 16px;
    outline: 0 !important;
    .StripeElement {
      &.StripeElement--focus {
        + label {
          color: $color-may !important;
          font-size: 11px;
          top: 10px;
        }
      }
      &.StripeElement--complete {
        + label {
          font-size: 11px;
          top: 10px;
        }
      }
      &.StripeElement--invalid {
        + label {
          color: $error-red;
          font-size: 11px;
          top: 10px;
        }
      }
    }
    label {
      @include font-body();
      transition: all 0.25s ease;
      position: absolute;
      color: $dark-grey;
      left: 10px;
      top: 22px;
      z-index: 2;
      font-size: 16px;
      line-height: 1.1em;
      pointer-events: none;
      span {
        color: $error-red;
      }
    }
  }
}
