.ReactModal__Body--open {
  overflow-y: hidden;
}

.ReactModalPortal {
  .ReactModal__Content {
    max-height: 95vh;
    // overflow: hidden !important;
    // padding: 0 !important;
    // .react-modal-outer {
    //     width: 100%;
    //     height: 95vh;
    // }
    // .react-modal-inner {
    //     width: 100%;
    //     height: 100%;
    //     overflow-x: hidden;
    //     overflow-y: auto;
    //     padding: 20px;
    // }
  }
  .Toastify__toast-container {
    position: absolute !important;
  }
}

.mod-header {
  text-align: center;
  margin-bottom: 15px;

  h4 {
    color: $slate-blue;
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
  }
}

.mod-body {
  font-size: 16px;
  text-align: center;
}

.mod-close {
  float: right;
  font-size: 1.35rem;
  font-weight: 700;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  border: none;
  background: none;
  position: fixed;
  right: 20px;
  top: 14px;
  cursor: pointer;
}

.mod-buttons {
  margin-top: 1rem;
  button {
    border-radius: 4px;
    color: #fff;
    text-transform: uppercase;

    &:hover {
      color: #fff;
    }

    &.primary {
      background: $error-red;
      font-size: 13px;
      width: 200px;
    }

    &.primary-green {
      background: $primary-green;
      font-size: 13px;
      width: 200px;

      .fa-check-circle {
        margin-right: 10px;
      }
    }

    &.secondary {
      background: $slate-blue;
      font-size: 11px;
      margin-top: 35px;
    }
  }
}

@media screen and (max-width: $breakpoint-md) {
  .ReactModalPortal {
    .ReactModal__Content {
      width: 90% !important;
      height: 90% !important;
      overflow-x: hidden !important;
      overflow-y: scroll !important;
    }
  }
}
