.submitted-appointments-sidebar {
  padding: 21px 0;
  background: linear-gradient(90.41deg, #1b5687 5.96%, #1f81a0 93.74%);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  text-align: center;
  h4 {
    color: #d2e3f1;
    margin-bottom: 12px;
    i {
      color: #d2e3f1;
    }
  }
  .appointment {
    + .appointment {
      margin-top: 43px;
    }
    .avatar {
      width: 70px;
      height: 70px;
      margin: 0 auto 10px;
    }
    p {
      &.title {
        @include font-body(700);
        text-transform: uppercase;
      }
      color: #fff;
      font-size: 13px;
      margin-bottom: 0;
      line-height: 1.5em;
    }
    a {
      display: inline-block;
    }
    i.arrow {
      font-size: 22px;
      margin-top: 12px;
      color: #fff;
    }
  }
}
