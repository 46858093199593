// Put any styles for "dashboard" like pages here (e.g. public section, login page)

.dashboard {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  .list-view {
    width: calc(100% - 440px);
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .calendar-view {
    width: 440px;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #f5f5f5;
  }
  .book-appt-btn {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px 15px;
    background: #dfdfdf;
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.05);
    .theme-btn {
      display: block;
      max-width: 100%;
      text-align: center;
      font-size: 16px;
    }
  }
  &.sitter {
    .sidebar {
      width: 260px;
      border: 0;
      overflow-x: hidden;
      overflow-y: auto;
    }
    .list-view {
      width: calc(100% - 440px - 260px);
    }
  }
  &.cover-requests-listing {
    display: flex;
    flex-flow: row wrap;
    .cover-requests-title {
      width: 100%;
      padding: 20px 30px;
      h3 {
        @include font-header(700);
        text-transform: uppercase;
        font-size: 22px;
        line-height: 1.1em;
        letter-spacing: 0.1em;
        color: $brand-blue;
        margin-bottom: 11px;
      }
      p {
        font-size: 14px;
        line-height: 1.25em;
        letter-spacing: 0;
        margin-bottom: 0;
      }
    }
    .list-view {
      width: 100%;
      .appointments-container {
        padding-top: 25px;
        > h2,
        .right-button {
          display: none !important;
        }
        .appointment-status-notice.cover-request {
          display: none !important;
        }
      }
    }
  }
}

.public-landing {
  text-align: center;
  h2 {
    margin-top: 45px;
  }
}

.login-page {
  margin-top: 45px;
}

@media screen and (max-width: $breakpoint-md) {
  .dashboard {
    flex-flow: row wrap;
    overflow-x: hidden;
    overflow-y: auto;
    &.family {
      padding-bottom: 82px;
    }
    &.sitter {
      .sidebar {
        width: 100%;
        height: auto;
      }
      .list-view {
        width: 100%;
      }
      .sitter-resources {
        align-items: stretch;

        .sitter-resource {
          width: 50%;
          display: flex;
          flex-flow: row nowrap;
          justify-content: center;
          align-items: center;
          a {
            font-size: 14px;
            padding: 12px 15%;
          }

          &:nth-child(2n + 1) {
            border-right: 1px solid #eeeeee;
          }
        }
      }
    }
    .list-view {
      width: 100%;
    }
  }
}

.public-dashboard {
  padding: 45px;
  text-align: center;
}

.pending-reviews-info {
  background: rgba(#f48693, 0.3);
  border: 1px solid #d2d2d2;
  border-radius: 6px;
  padding: 15px;
  margin: 25px 0 0;
  display: grid;
  grid-template-columns: 1fr 25px;

  p {
    font-size: 13px;
    letter-spacing: 0;
    margin: 0;
  }

  button {
    border: none;
    background: none;
    padding: 0;
    font-size: 25px;
    color: $slate-blue;
  }
}

.pending-reviews-info__title {
  font-weight: bold;
  text-transform: uppercase;
  font-family: $rubik;
  color: $dark-grey;
  margin: 0 0 5px;
  font-size: 13px;
}
