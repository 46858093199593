.sitters-main {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.sitters-container {
  padding: 15px;
  max-width: 1230px;
  margin: 0 auto;

  .section-label {
    margin-bottom: 15px;
  }

  .search-container {
    margin-bottom: 30px;
  }

  @media screen and (min-width: $breakpoint-md) {
    padding: 30px;

    .search-container {
      margin-bottom: 40px;
    }
  }
}

.sitters-header {
  display: flex;
  justify-content: space-between;

  .back-link {
    font-size: 0.75rem;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.sitters-section-nav {
  display: flex;
  margin: 20px 0 30px;

  a + a {
    margin-left: 15px;
  }
}

.sitter-listing {
  & > .sitter-row + .sitter-row {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #eee;
  }
}

.sitter-row {
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  align-items: center;
  gap: 15px;

  @media screen and (min-width: $breakpoint-md) {
    gap: 40px;
  }
}

.sitter-row__avatar {
  position: relative;
  img {
    display: flex;
    border-radius: 9999rem;
    background: rgba(#000, 0.1);
    text-align: center;
    font-size: 12px;
  }
}

.sitter-row__action {
  margin: -10px auto 0;
  width: 28px;
  height: 28px;

  &.blocked {
    background: $error-red;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    span {
      font-size: 12px;
    }
  }
}

.sitter-row__description h3 {
  font-size: 18px;
  margin-bottom: 0;
}

.sitter-row__tagline {
  font-size: 14px;
}

.sitter-row__description__state {
  font-size: 10px;
  font-weight: bold;
  margin-top: 10px;
  color: $slate-blue;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.sitter-row__description__state.blocked {
  color: $error-red;

  span {
    color: $slate-blue;
    margin-left: 5px;
    font-size: 16px;
    vertical-align: center;
  }
}

.sitter-row__description__state.saved {
  color: $brand-blue;
}

.sitter-favorite-toggler {
  border: none;

  padding: 0;
  width: 100%;
  height: 100%;
  background: #d2d2d2;
  color: #fff;
  border-radius: 100%;

  span {
    font-size: 14px;
    display: block;
  }

  &.is-active {
    background: #fd9742;
  }
}

.sitters-search-note {
  position: relative;
  background: rgba(244, 134, 147, 0.3);
  border: 1px solid #d2d2d2;
  padding: 10px 15px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.sitters-search-note__head {
  position: static;

  h3 {
    font-weight: bold;
    font-size: 13px;
    line-height: 1.5;
    text-transform: uppercase;
    color: #2c2c2c;
    margin: 0;
  }

  button {
    position: absolute;
    background: none;
    border: none;
    border-radius: 4px;
    inset: 0;
    width: 100%;
    height: 100%;
    padding: 0;

    &:focus {
      outline: none;
    }

    &:focus-visible {
      box-shadow: 0 0 0 2px rgba($brand-blue, 30%);
    }

    span {
      position: absolute;
      right: 15px;
      top: 10px;
      display: block;
      font-size: 14px;
      transform: rotate(0);
      transition: transform 0.3s ease;
    }

    @media screen and (min-width: $breakpoint-md) {
      display: none;
    }
  }
}

.sitters-search-note__body {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;

  p {
    font-size: 13px;
    letter-spacing: 0.05rem;
    margin-bottom: 0;
  }

  @media screen and (min-width: $breakpoint-md) {
    max-height: none;
  }
}

.sitters-search-note.is-expanded {
  .sitters-search-note__head button span {
    transform: rotateX(180deg);
  }

  .sitters-search-note__body {
    max-height: 1000px;
  }
}

.sitter-search-form {
  margin-bottom: 20px;

  @media screen and (min-width: $breakpoint-md) {
    display: grid;
    grid-template-columns: 2fr 2fr 3fr;
    gap: 20px;

    .form-group,
    .form-field {
      margin-bottom: 0;
    }
  }
}

.sitters-load-more {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}

.sitter-profile-actions {
  position: fixed;
  z-index: 500;
  inset: auto 0 0 0;
  background: #eee;
  text-align: center;
  padding: 15px;
  transition: transform 0.5s ease 0.05s;

  div {
    margin-bottom: 15px;
  }

  body.menu-open & {
    transform: translateX(-$menuWidth);
  }
}
