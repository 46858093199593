.admin-detail.admin-program-booking {
  h4.program-title {
    @include font-header(700);
    color: $primary-green;
    font-size: 20px;
    margin-bottom: 16px;
  }
  .content {
    padding: 0 !important;
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    .main-panel {
      width: calc(100% - 315px);
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      padding: 27px 24px;
    }
    .sidebar-right {
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
  .appt-people {
    margin-bottom: 26px;
    padding-bottom: 30px;
    border-bottom: 1px solid #d2d2d2;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: stretch;
    .admin-avatar-section {
      max-width: none;
      width: 380px;
    }
    .program-pill-wrapper {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: flex-end;
      width: calc(100% - 380px);
      max-width: 560px;
    }
    .program-pill {
      width: 30%;
      background-color: #fff;
      border-radius: 4px;
      text-align: center;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: center;
      padding: 20px 0 13px;
      h3 {
        width: 100%;
        margin-bottom: 5px;
        @include font-body(700);
        color: $brand-blue;
        font-size: 20px;
        line-height: 1em;
        text-transform: uppercase;
        letter-spacing: 0;
      }
      p {
        width: 100%;
        color: $brand-blue;
        margin-bottom: 0;
        font-size: 12px;
        line-height: 2em;
        text-transform: uppercase;
        letter-spacing: 0;
      }
    }
  }
  .admin-program-booking-tabs {
    background-color: #fff;
    .tabs-header {
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: space-between;
      border: 1px solid #eeeeee;
      .tab-item {
        width: 33.3333%;
        text-align: center;
        background-color: #fff;
        padding: 8px 0;
        cursor: pointer;
        p {
          @include font-body(700);
          color: $slate-blue;
          font-size: 12px;
          text-transform: uppercase;
          line-height: 2em;
          margin-bottom: 0;
        }
        &.active {
          background-color: $brand-blue;
          p {
            color: #fff;
          }
        }
        + .tab-item {
          border-left: 1px solid #eeeeee;
        }
      }
    }
    .tabs-body {
      padding: 20px;
      border: 1px solid #eeeeee;
      border-top: 0;
    }
  }
  .sitter-signups {
    margin-bottom: 24px;
    > h4 {
      @include font-body(700);
      font-size: 16px;
      line-height: 1.25em;
      letter-spacing: 0;
      margin-bottom: 9px;
      color: $dark-grey;
    }
  }
  .signup-table {
    width: 100%;
    display: table;
    table-layout: fixed;
    border: 1px solid #eeeeee;
    .signup-row {
      display: table-row;
      &.header {
        background: #f6f6f6;
        p {
          padding: 0 0 0 11px;
          @include font-header(400);
          text-transform: uppercase;
          color: $slate-blue;
          font-size: 11px;
          letter-spacing: 0.1em;
        }
      }
    }
    .signup-col {
      display: table-cell;
      + .signup-col {
        border-top: 1px solid #eeeeee;
      }
    }
    p {
      margin-bottom: 0;
      font-size: 16px;
      line-height: 2em;
      letter-spacing: 0;
      padding: 4px 0 4px 11px;
    }
  }
  .communications-section {
    h5 {
      @include font-header(700);
      font-size: 12px;
      line-height: 1.1em;
      margin-bottom: 0;
      letter-spacing: 0.1em;
      color: $slate-blue;
      text-transform: uppercase;
    }
    button {
    }
    + .communications-section {
      padding-top: 32px;
    }
    .communication-item {
      padding-top: 20px;
      h4 {
        font-size: 14px;
        line-height: 1.5em;
        margin-bottom: 3px;
      }
      p {
        font-size: 16px;
        line-height: 1.5em;
        margin-bottom: 9px;
        letter-spacing: 0;
        &.info {
          font-style: italic;
          font-size: 13px;
          line-height: 1.1em;
          margin-bottom: 0;
          color: $slate-blue;
          letter-spacing: 0.1em;
        }
      }
    }
  }
  .admin-booking-form {
  }
  .calendar-view {
    .arrow {
      appearance: none;
      border: 0;
      background-color: transparent;
      position: absolute;
      z-index: 5;
      top: -4px;
      i {
        color: $slate-blue;
      }
      &.prev {
        left: 0;
      }
      &.next {
        right: 0;
      }
      &.disabled {
        i {
          color: #d8d8d8;
        }
        cursor: not-allowed;
        transition: all 0.25s ease;
      }
    }
    .calendars {
      display: flex;
      flex-flow: row nowrap;
      align-items: flex-start;
      justify-content: space-between;
      .calendar {
        width: 49%;
        margin: 0;
      }
    }
  }
  .appointment-row {
    padding: 20px 0;
    &.invalid {
      background-color: rgba(252, 92, 101, 0.1);
    }
    + .appointment-row {
      border-top: 1px solid #d2d2d2;
    }
  }
  .appointment-main-row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: stretch;
    margin-left: -11px;
    margin-right: -11px;
  }
  .appointment-sub-row {
    padding-top: 10px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;
    margin-left: -11px;
    margin-right: -11px;
  }
  .form-field-wrapper {
    padding: 0 11px;
    &.date,
    &.start_time,
    &.end_time,
    &.children,
    &.overnight,
    &.provider {
      width: calc(85% / 6);
    }
    &.editing {
      width: 5%;
      display: flex;
      justify-content: center;
      margin-top: 20px;
      label {
        font-size: 0;
        line-height: 0;
        .checkbox {
          margin-right: 0;
        }
      }
    }
    &.show-edit {
      width: 5%;
      text-align: center;
    }
    &.cancel {
      width: 5%;
      text-align: center;
    }
    &.flex {
      width: 250px;
      display: flex;
      flex-flow: row nowrap;
      .form-field {
        width: 50%;
      }
    }
    &.driving {
      width: 250px;
      display: flex;
      flex-flow: row nowrap;
      .form-field {
        width: 50%;
      }
    }
  }
  .appointment-fields {
    h4 {
      @include font-header(700);
      color: $slate-blue;
      font-size: 12px;
      line-height: 1.1em;
      text-transform: uppercase;
      margin-bottom: 5px;
    }
  }
  .bulk-edit {
    margin-top: 30px;
    margin-bottom: 20px;
    .theme-btn {
      margin-bottom: 20px;
    }
  }
  .form-field {
    margin-bottom: 0;
    &.all_children {
      margin-bottom: 4px;
    }
    &.child-checkbox {
      label.title {
        display: none;
      }
      .avatar {
        display: none;
      }
      .label {
        width: 100%;
        display: flex;
        padding-left: 0;
        .checkbox {
          order: 1;
        }
        p {
          order: 2;
          font-weight: 400;
        }
      }
      .checkbox-group {
        width: 100%;
        .avatar {
          display: none !important;
        }
        label {
          width: 100%;
        }
      }
    }
    &.num-nights {
      flex-flow: row wrap;
      margin-top: 5px;
      label {
        width: 100%;
        display: block;
      }
    }
    &.radio {
      label {
        width: 100%;
      }
      .radio-group {
        width: auto;
        display: inline-block;
        margin-right: 8px;
      }
    }
  }
  .default-appointment-settings {
    background-color: rgba($primary-green, 0.2);
    border-radius: 4px;
    padding: 11px 10px;
    margin-top: 30px;
    > h4.title {
      @include font-header(700);
      color: $slate-blue;
      font-size: 12px;
      line-height: 1.1em;
      text-transform: uppercase;
      margin-bottom: 5px;
    }
    .appointment-row {
      padding: 0;
      margin: 0;
      border-bottom: 0;
    }
    .form-field-wrapper {
      width: calc(100% / 7);
      &.flex,
      &.driving {
        flex-flow: row wrap;
        .form-field {
          width: 100%;
        }
      }
    }
  }
  .btn-wrapper {
    margin-top: 16px;
  }
  .show-extra-btn {
    width: 25px;
    height: 25px;
    margin-top: 18px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    span {
      display: block;
      height: 5px;
      width: 5px;
      border-radius: 50%;
      background-color: $brand-blue;
    }
  }
  .cancel-btn {
    appearance: none;
    display: inline-block;
    width: 25px;
    height: 25px;
    background-color: $error-red;
    margin-top: 18px;
    border: 0;
    border-radius: 50%;
    span {
      @include font-header(400);
      cursor: pointer;
      color: #fff;
      font-size: 11px;
      line-height: 1.1em;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.bulk-edit-modal {
  .bulk-edit-row {
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
  }
}
