main.unclaimed {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.unclaimed-container {
  padding: 15px;
  max-width: 1230px;
  margin: 0 auto;

  .section-label {
    margin-bottom: 15px;
  }

  @media screen and (min-width: $breakpoint-md) {
    padding: 30px;
  }
}

.unclaimed-filters {
  border: 1px solid #d2d2d2;
  background: rgba($primary-green, 0.1);
  border-radius: 4px;
  padding: 10px 15px;
  margin-bottom: 20px;
}

.unclaimed-filters__head {
  position: static;

  h3 {
    font-weight: bold;
    font-size: 13px;
    line-height: 1.5;
    text-transform: uppercase;
    color: #2c2c2c;
    margin: 0;
  }

  button {
    position: absolute;
    background: none;
    border: none;
    border-radius: 4px;
    inset: 0;
    width: 100%;
    height: 100%;
    padding: 0;

    &:focus {
      outline: none;
    }

    &:focus-visible {
      box-shadow: 0 0 0 2px rgba($brand-blue, 30%);
    }

    span {
      position: absolute;
      right: 15px;
      top: 10px;
      display: block;
      font-size: 14px;
      transform: rotate(0);
      transition: transform 0.3s ease;
    }
  }
}

.unclaimed-filters__body {
  display: none;
}

.unclaimed-filters.is-expanded {
  .unclaimed-filters__head button span {
    transform: rotateX(180deg);
  }

  .unclaimed-filters__body {
    display: block;
  }
}

.unclaimed-appointments-form {
  margin-top: 10px;

  @media screen and (min-width: $breakpoint-md) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px 20px;

    .form-group,
    .form-field {
      margin-bottom: 0;
    }
  }
}

.unclaimed-appointments-listing {
  list-style: none;
  padding: 0;

  & > li + li {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #eee;
  }
}

.unclaimed-appointment-row {
  display: grid;
  grid-template-columns: 1fr 1fr 25px;
  column-gap: 10px;
  align-items: center;
  border-left: 3px solid $primary-green;
  padding-left: 6px;
}

.unclaimed-appointment-row__date__day {
  display: flex;
  flex-wrap: wrap;
}

.unclaimed-appointment-row__date__day h3 {
  font-weight: bold;
  font-size: 14px;
  color: $dark-grey;
  margin: 0;
  text-transform: uppercase;
  margin-right: 5px;
}

.unclaimed-appointment-row__date__day div {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
  color: $slate-blue;
}

.unclaimed-appointment-row__date__time {
  font-size: 12px;
  color: $dark-grey;
  line-height: 1.2;
}

.unclaimed-appointment-row__date__time span {
  color: $slate-blue;
  display: block;
}

.unclaimed-appointment-row__details {
  display: grid;
  grid-template-columns: 40px 1fr;
  column-gap: 5px;
  align-items: center;

  dl,
  dd {
    margin: 0;
    line-height: 1.2;
  }

  dd {
    font-size: 12px;
    color: $slate-blue;
  }
}

.unclaimed-appointment-row__details__avatar .avatar {
  width: 40px;
  height: 40px;
  i {
    font-size: 16px;
  }
}

.unclaimed-appointment-row__details__family {
  font-weight: bold;
  dd {
    color: $dark-grey;
  }
}

.unclaimed-appointment-row__action {
  button {
    color: $light-grey;
    transition: color 0.25s ease;
    font-size: 22px;
    background: none;
    border: none;
    padding: 0;
  }

  button:hover {
    color: $slate-blue;
  }
}

.unclaimed-appointment-row__meta {
  grid-column-start: 1;
  grid-column-end: 4;

  .warning-text {
    font-size: 10px;
  }
  .bonus-info {
    margin-top: 10px;
  }
}

@media screen and (min-width: $breakpoint-md) {
  .unclaimed-appointment-row__date {
    display: grid;
    grid-template-columns: 140px 1fr;
    align-items: center;
    gap: 25px;
  }
  .unclaimed-appointment-row__date__day {
    display: block;
  }

  .unclaimed-appointment-row__date__day h3 {
    font-size: 15px;
    margin-right: 0;
  }

  .unclaimed-appointment-row__date__day div {
    font-size: 12px;
  }

  .unclaimed-appointment-row__date__time {
    font-size: 14px;
  }

  .unclaimed-appointment-row__date__time span {
    display: inline;
  }

  .unclaimed-appointment-row__details {
    grid-template-columns: 60px 1fr;
    column-gap: 15px;

    dd {
      font-size: 14px;
    }
  }

  .unclaimed-appointment-row__details__inner {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .unclaimed-appointment-row__details__avatar .avatar {
    width: 60px;
    height: 60px;
    i {
      font-size: 20px;
    }
  }
}
