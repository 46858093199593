// Active styles for when the header is opened (e.g. the right value) are in the _global file under "body.menu-open .section"

header {
  position: fixed;
  transition: $menuTransitionProps;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0 15px;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: space-between;
  &.public {
    background-color: $brand-blue;
  }
  .logo {
    width: 50px;
    padding: 10px 0;
    a {
      display: block;
    }
    img {
      width: 50px;
      height: 50px;
      object-fit: cover;
    }
  }
  .menus {
    width: calc(100% - 50px);
    height: 70px;
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: flex-end;
  }

  .menu-opener {
    padding: 10px 0;
    width: 200px;
    border: none;
    background: none;
    border-top: 3px solid #f48693;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-around;
    .avatar {
      width: 50px;
      height: 50px;
      margin-right: 10px;
      i {
        font-size: 18px;
      }
    }
    .welcome {
      margin-right: 10px;

      h2 {
        font-size: 14px;
        font-weight: 300;
        margin-top: 0;
        margin-bottom: 0;
      }

      .name {
        font-size: 18px;
        font-weight: 300;
      }

      p {
        @include font-header(700);
        font-size: 10px;
        margin-top: 4px;
        margin-bottom: 0;
        line-height: 1.1em;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        text-decoration: underline;
        &.balance {
          color: $error-red;
        }
        &.credit {
          color: $primary-green;
        }
      }
    }
  }

  @media screen and (min-width: $breakpoint-md) {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.hamburger-wrapper {
  .hamburger {
    width: 18px;
    span {
      display: block;
      background-color: $brand-blue;
      height: 3px;
      border-radius: 3px;
      position: absolute;
      left: 0;
      &:nth-of-type(1) {
        top: 0;
        width: 18px;
      }
      &:nth-of-type(2) {
        top: 50%;
        transform: translateY(-50%);
        width: 14px;
      }
      &:nth-of-type(3) {
        bottom: 0;
        width: 16px;
      }
    }
  }
}

.admin-search-bar {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  margin-right: 50px;
  .field-wrapper {
    .icon {
      position: absolute;
      top: 50%;
      left: 13px;
      transform: translateY(-50%);
      z-index: 2;
      i {
        color: $primary-green;
        font-size: 16px;
      }
    }
    input {
      width: 400px;
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      color: $slate-blue;
      border: 1px solid #d2d2d2;
      border-radius: 4px;
      padding-left: 41px;

      &.active {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
  .results-wrapper {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    .result {
      display: flex;
      flex-flow: row nowrap;
      background-color: #fff;
      border: 1px solid #d2d2d2;
      border-top: 0;
      height: 50px;
      text-decoration: none;
      .type {
        width: 85px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          width: 65px;
          text-align: center;
          border-radius: 10px;
          color: #fff;
          font-size: 11px;
          text-transform: uppercase;
          letter-spacing: 0.05em;
        }
      }
      .name {
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          margin-bottom: 0;
        }
      }
      &:nth-last-of-type(1) {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
      &.member {
        .type span {
          background-color: $brand-blue;
        }
      }
      &.provider {
        .type span {
          background-color: $primary-green;
        }
      }
      &.family {
        .type span {
          background-color: $error-red;
        }
      }
      &.appointment {
        .type span {
          background-color: $slate-blue;
        }
      }
    }
  }
}

.menu-links {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  padding-right: 40px;
  height: 100%;
  .menu-link-group {
    margin-right: 20px;
    height: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    &:nth-last-of-type(1) {
      margin-right: 0;
    }
    &:hover {
      .menu-link-group-hover {
        display: block;
      }
    }
    .menu-link-group-title {
      h4 {
        text-transform: uppercase;
        margin-bottom: 0;
        a {
          color: $brand-blue;
          text-decoration: none;
        }
      }
    }
    .menu-link-group-hover {
      display: none;
      position: absolute;
      top: calc(100% - 15px);
      left: 50%;
      transform: translateX(-50%);
      padding: 8px 19px 0;
      border-top: 3px solid $brand-blue;
      background-color: #fff;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      width: 250px;
      z-index: 2;
      &:before {
        content: '';
        position: absolute;
        bottom: 100%;
        left: 50%;
        z-index: 3;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 7.5px 11px 7.5px;
        border-color: transparent transparent $brand-blue transparent;
        margin-left: -7.5px;
      }
      p {
        margin-bottom: 5px;
        line-height: 1.1em;
        padding: 0.5em;
        letter-spacing: 0;
        a {
          color: $dark-grey;
          text-decoration: none;
        }
      }
    }
  }
}

.menu {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 100%;
  width: $menuWidth;
  height: 100%;
  transition: $menuTransitionProps;
  &.family {
    background: $gradient-salmon;
  }
  &.sitter {
    background: $gradient-green;
  }

  .close-menu {
    position: absolute;
    top: 12px;
    left: 15px;
    z-index: 5;

    button {
      background: none;
      border: none;
      padding: 0;
    }
    i.fas {
      font-size: 34px;
      color: #fff;
    }
  }
  .menu-content {
    width: 100%;
    height: 100%;
    padding-top: 20px;
    padding-bottom: 40px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .avatar {
    text-align: center;
    width: 100%;
    .avatar-wrapper {
      border: 5px solid #fff;
      width: 150px;
      height: 150px;
      margin: auto;
    }
  }
  h3 {
    text-align: center;
    font-size: 24px;
    line-height: 1.1em;
    margin-top: 8px;
    margin-bottom: 32px;
  }
  .link-group {
    width: 100%;
    text-align: center;
    p {
      @include font-header(300);
      margin-bottom: 30px;
      font-size: 20px;
      a {
        color: $dark-grey;
        text-decoration: none;
      }
    }
  }
  .logout {
    // position: absolute;
    // z-index: 5;
    // bottom: 50px;
    // left: 0;
    width: 100%;
    text-align: center;
    button {
      display: inline-block;
    }
  }
  &.admin {
    .logout {
      position: relative;
      left: auto;
      bottom: auto;
    }
  }
}

@media screen and (max-width: $breakpoint-md) {
  header {
    .notifications {
      margin-right: 20px;
    }
    .menu-links {
      display: none;
    }
  }
  .menu {
    width: 100%;
  }
}
