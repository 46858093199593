.warning-text {
  display: inline-flex;
  color: $primary-orange;
  font-weight: bold;
  text-transform: uppercase;
  align-items: center;
  font-size: 14px;

  .warning-text__icon {
    flex-shrink: 0;
    margin-right: 10px;
  }
  .warning-text__body {
    flex-grow: 1;
  }
}
