.admin-listing {
  h4.section-label {
    margin-bottom: 16px;
  }
  .content {
    .filter-btn-wrapper {
      margin-bottom: 16px;
    }
  }
  .admin-balance-section {
    margin-top: 24px;
    h4 {
      margin-bottom: 0;
    }
    p {
      font-size: 16px;
      font-weight: 700;
      line-height: 1.25em;
      letter-spacing: 0.1em;
      color: $slate-blue;
      &.balance {
        color: $error-red;
      }
      &.credit {
        color: $primary-green;
      }
    }
    a {
      display: block;
      margin-top: 8px;
      font-size: 12px;
      color: $slate-blue;
    }
    table {
      margin-top: 10px;
      td,
      th {
        padding-right: 20px;
        &.right {
          text-align: right;
        }
      }
    }
  }
  .btn-wrapper {
    margin-bottom: 24px;
    a {
      display: inline-block;
    }
  }
  &.with-sidebar-right {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    .content {
      padding-left: 20px;
      padding-right: 20px;
      width: calc(100% - 315px);
    }
    .sidebar-right {
      width: 315px;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
}

.status-legend {
  text-align: left;
  p {
    letter-spacing: 0;
  }
}

.kendo-table {
  .k-grid .k-grid-header .k-header .k-link {
    height: auto;
  }

  .k-grid .k-grid-header .k-header {
    white-space: normal;
  }

  .k-grid-header .k-header {
    padding: 11px 12px;
    > div {
      position: initial;
      > div {
        top: 7px;
      }
    }
    .k-icon {
      font-size: 10px;
    }
    .k-i-more-vertical {
      font-size: 19px;
    }
    .k-link {
      @include font-header(400);
      font-size: 10px;
      line-height: 1.25em;
      letter-spacing: 0.1em;
      color: $slate-blue;
      text-transform: uppercase;
      position: initial;
    }
  }
  .manage-cell {
    a.theme-btn {
      padding: 7px 16px;
    }
  }
  .family-cell {
    .avatar {
      width: 30px;
      height: 30px;
      vertical-align: middle;
      display: inline-block;
      margin-right: 5px;
      .avatar-wrapper {
        background-color: $primary-coral;
      }
      i {
        color: #fff;
        font-size: 16px;
      }
    }
  }
  .avatar-cell {
    .avatar {
      width: 30px;
      height: 30px;
      vertical-align: middle;
      display: inline-block;
      margin-right: 5px;
      .avatar-wrapper {
        background-color: $primary-coral;
      }
      i {
        color: #fff;
        font-size: 16px;
      }
    }
    i.fas.fa-star {
      color: $color-favorite;
      margin-left: 10px;
      font-size: 11px;
    }
    i.fas.fa-save {
      color: $light-grey;
      margin-left: 10px;
      font-size: 11px;
    }
    &.sitter {
      span {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        .avatar-content {
          width: calc(100% - 35px);
        }
      }
      .auto-assigning {
        color: $brand-blue;
        font-weight: 700;
      }
    }
  }
  .availability-cell {
    .availability-slot {
      display: inline-block;
      margin-right: 9px;
      background-color: $error-red;
      padding: 4px 10px 4px 37px;
      text-decoration: none !important;
      border-radius: 4px;
      margin-bottom: 5px;
      &:before {
        content: '\f00d';
        font-family: 'Font Awesome 5 Pro';
        position: absolute;
        left: 11px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 18px;
        color: #fff;
      }
      &.active {
        background-color: $primary-green;
        &:before {
          content: '\f00c';
          left: 10px;
        }
      }
      p {
        @include font-body(700);
        font-size: 14px;
        line-height: 1.5em;
        letter-spacing: 0;
        color: #fff;
        margin-bottom: 0;
        &.small {
          @include font-body(400);
        }
      }
    }
  }
  .status-cell {
    z-index: 5;
    text-align: center;
    overflow: visible;
    // I don't know why i did this, instead of just making yellow the default and overwriting it as needed
    // Commenting them out just in case this fix breaks something and needs to be reverted - remove later (11/10/21)
    // &.pending,
    // &.submitted,
    // &.time-change-requested,
    // &.pending-cover,
    // &.program-pending,
    // &.program-pending,
    // &.auto-assigning,
    // &.admin-priority,
    // &.pending-admin,
    // &.pending-list,
    // &.pending-list-approval,
    // &.cover-acceptance-needed {
    //   i {
    //   }
    // }
    i {
      color: $color-favorite;
      font-size: 20px;
    }
    &.completed,
    &.accepted {
      i {
        color: $primary-green;
      }
    }
    &.cancelled {
      i {
        color: $error-red;
      }
    }
    &.time-change-requested {
      .tooltip-wrapper {
        .tooltip-item {
          width: 115px;
        }
      }
    }
    .tooltip-wrapper {
      position: relative;
      top: auto;
      left: auto;
      transform: none;
      .inner {
        display: inline-block;
      }
      .tooltip-item {
        background-image: url('/assets/images/tooltip-bg-small-left.png');
        left: 100%;
        right: auto;
        padding: 7px 9px 7px 11px;
        width: auto;
      }
    }
  }
  .k-pager-numbers {
    .k-link {
      font-size: 13px;
      line-height: 0;
      &.k-state-selected {
        background-color: $primary-green;
        border-radius: 50%;
        color: #fff;
      }
    }
  }
}

.admin-manage-balance {
  max-width: 232px;
  margin: auto;
  .button-toggler {
    display: flex;
    width: 100%;
    flex-flow: row nowrap;
    align-items: stretch;
    margin-bottom: 24px;
    .option {
      width: 50%;
      border: 1px solid #d8d8d8;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: all 0.25s ease;
      padding: 17px 0;
      &:nth-of-type(1) {
        border-radius: 4px 0 0 4px;
        border-right: 0;
      }
      &:nth-of-type(2) {
        border-radius: 0 4px 4px 0;
        border-left: 0;
      }
      &.active {
        &.balance {
          background-color: $error-red;
          border-color: $error-red;
        }
        &.credit {
          background-color: $primary-green;
          border-color: $primary-green;
        }
        p {
          color: #fff;
        }
      }
      p {
        margin-bottom: 0;
        font-size: 12px;
        text-transform: uppercase;
        line-height: 1.25em;
        transition: all 0.25s ease;
      }
    }
  }
  input {
    @include font-body(700);
    width: 100%;
    height: 60px;
    font-size: 28px;
    text-align: center;
    line-height: 1.1em;
    letter-spacing: 0.1em;
    color: $dark-grey;
    border: 1px solid #eeeeee;
    border-radius: 4px;
  }
  textarea {
    padding: 12px;
    width: 100%;
    font-size: 14px;
    color: $dark-grey;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    line-height: 1.1em;
    letter-spacing: 0.1em;
    margin-top: 24px;
  }
  .theme-btn {
    margin-top: 24px;
  }
}
