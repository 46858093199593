.manage {
  .subscription-cancellation {
    text-align: center;
    margin-top: 24px;
    padding-bottom: 24px;
    button {
      display: inline-block;
    }
  }

  .providers-btn-wrapper {
    margin-bottom: 28px;
  }

  .content {
    h2 {
      font-size: 22px;
      font-weight: 700;
      line-height: 1.1em;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: $brand-blue;
      margin-bottom: 24px;
    }
  }
}
