.appointments-container {
  padding: 45px 30px 30px;
  h2 {
    @include font-header();
    color: $slate-blue;
    font-size: 14px;
    line-height: 1.1em;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    i {
      color: $primary-green;
      font-size: 20px;
      vertical-align: sub;
      margin-right: 9px;
    }
  }
  .right-button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: -30px;
    cursor: pointer;
    i {
      font-size: 18px;
      color: $primary-green;
      margin-right: 5px;
    }
    span {
      font-size: 14px;
      line-height: 1.1em;
    }
  }
  .filter-options {
    padding: 30px 0;
    button {
      margin-right: 10px;
    }
  }
  .appointment-list-item {
    padding: 30px;
    margin-left: -30px;
    margin-right: -30px;
    border-bottom: 1px solid #eeeeee;
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    justify-content: space-between;

    &.pending,
    &.pending-list,
    &.pending-list-approval,
    &.submitted {
      background-color: rgba(244, 134, 147, 0.1);
    }
  }
  .date-day {
    border-left: 3px solid $dark-grey;
    padding-left: 6px;
    display: flex;
    align-items: center;
    width: 25%;
    &.jan {
      border-color: $color-jan;
    }
    &.feb {
      border-color: $color-feb;
    }
    &.mar {
      border-color: $color-mar;
    }
    &.apr {
      border-color: $color-apr;
    }
    &.may {
      border-color: $color-may;
    }
    &.jun {
      border-color: $color-jun;
    }
    &.jul {
      border-color: $color-jul;
    }
    &.aug {
      border-color: $color-aug;
    }
    &.sep {
      border-color: $color-sep;
    }
    &.oct {
      border-color: $color-oct;
    }
    &.nov {
      border-color: $color-nov;
    }
    &.dec {
      border-color: $color-dec;
    }
    h3 {
      font-size: 16px;
      font-weight: 700;
      line-height: 1.5em;
      letter-spacing: 0.02em;
      margin-bottom: 0;
      text-transform: uppercase;
      &.weekday {
        color: $slate-blue;
        font-size: 14px;
      }
    }
  }
  .time {
    display: flex;
    align-items: center;
    width: 33.3333%;
    p {
      font-size: 14px;
      line-height: 1.25em;
      margin-bottom: 0;
      letter-spacing: 0;
      span.duration {
        color: $slate-blue;
      }
      &.time-change {
        color: $color-favorite;
      }
    }
  }
  .sitters {
    display: flex;
    align-items: center;
    width: 33.3333%;
    .sitter {
      display: flex;
      align-items: center;
      flex-flow: row nowrap;
    }
    .avatar {
      width: 60px;
      height: 60px;
      i {
        font-size: 18px;
      }
    }
    .name {
      padding-left: 8px;
    }
    p {
      font-weight: 700;
      font-size: 14px;
      line-height: 1.25em;
      margin-bottom: 0;
      letter-spacing: 0;
      &.pending {
        i {
          font-size: 18px;
          vertical-align: middle;
        }
        font-weight: 700;
        text-transform: uppercase;
        color: $brand-blue;
      }
    }
  }
  .family-wrapper {
    display: flex;
    align-items: center;
    width: 33.3333%;
    .family {
      display: flex;
      align-items: center;
      flex-flow: row nowrap;
    }
    .avatar {
      width: 60px;
      height: 60px;
      i {
        font-size: 18px;
      }
    }
    .name {
      padding-left: 8px;
    }
    p {
      font-weight: 700;
      font-size: 14px;
      line-height: 1.25em;
      margin-bottom: 0;
      letter-spacing: 0;
      span {
        font-weight: 400;
        color: $slate-blue;
      }
    }
  }
  .manage {
    width: 8.3333%;
    display: flex;
    align-items: center;
    a {
      display: block;
      width: 100%;
      text-align: right;
    }
    i.fas {
      color: $light-grey;
      transition: all 0.25s ease;
      font-size: 22px;
      &:hover {
        color: $slate-blue;
      }
    }
  }
  .mobile-view-toggle {
    display: none;
  }
  .appointment-status-notice {
    display: none;
    width: 100%;
    text-align: center;
    margin-top: 12px;
    p {
      font-size: 14px;
      line-height: 1.25em;
      margin-bottom: 0;
      color: $color-favorite;
    }
    &.always-visible {
      display: block;
    }
  }
}

.appointment-list-item__actions {
  margin-top: 15px;
  text-align: center;
  width: 100%;
}

@media screen and (max-width: $breakpoint-md) {
  .appointments-container {
    padding: 35px 15px 15px;
    h2 {
      font-size: 11px;
      margin-bottom: 14px;
    }
    .right-button {
      i {
        font-size: 14px;
      }
      span {
        font-size: 11px;
      }
    }
    .mobile-view-toggle {
      display: flex;
      flex-flow: row nowrap;
      text-align: center;
      margin-top: 20px;
      border-bottom: 1px solid #e5e5e5;
      margin-left: -15px;
      margin-right: -15px;
      &.list {
        &:before {
          left: 0;
        }
        .list {
          p {
            color: $primary-green;
            i {
              color: $primary-green;
            }
          }
        }
      }
      &.calendar {
        &:before {
          left: 50%;
        }
        .calendar {
          p {
            color: $primary-green;
            i {
              color: $primary-green;
            }
          }
        }
      }
      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 50%;
        height: 5px;
        transform: translateY(50%);
        transition: all 0.25s ease;
        background-color: $primary-green;
        z-index: 2;
      }
      .list,
      .calendar {
        width: 50%;
        padding: 9px;
        margin: 0;
      }
      p {
        font-size: 14px;
        font-weight: 700;
        line-height: 1.1em;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: $slate-blue;
        transition: all 0.25s ease;
        margin-bottom: 0;
        i {
          transition: all 0.25s ease;
          color: $slate-blue;
          margin-right: 5px;
        }
      }
    }
    .appointment-list-item {
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 15px;
      padding-right: 15px;
      margin-left: -15px;
      margin-right: -15px;
    }
    .date-day {
      width: 24%;
      h3 {
        font-size: 14px;
      }
      span {
        display: block;
      }
    }
    .time {
      width: 35%;
      p {
        span {
          display: block;
        }
        span.desktop {
          display: none !important;
        }
      }
    }
    .family-wrapper {
      width: 36%;
      padding-right: 2%;
      .avatar {
        width: 40px;
        height: 40px;
      }
      p {
        span {
          display: block;
        }
      }
    }
    .sitters {
      width: 36%;
      padding-right: 2%;
      .avatar {
        width: 40px;
        height: 40px;
      }
    }
    .manage {
      width: 5%;
    }
    .appointment-status-notice {
      display: block;
    }
  }
}
